import React from "react";
import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import LibraryAddCheckIcon from "@material-ui/icons/LibraryAddCheck";
import { TableColumnTypes } from "src/common-types";
import { TableHeaderProps } from "./table-header-types";
import { headerStyles } from "./table-header-styles";

export const TableHeaderComponent = (props: TableHeaderProps) => {
  const {
    numSelected,
    onSelectAllClick,
    rowCount,
    order,
    orderBy,
    onRequestSort,
    headCellsData,
  } = props;

  const classes = headerStyles();
  const createSortHandler = (property: string) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          className={classes.headerCell}
          style={{ borderLeft: "none" }}
        >
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
            icon={<FilterNoneIcon />}
            checkedIcon={<LibraryAddCheckIcon />}
          />
        </TableCell>
        {headCellsData.map((headCell: TableColumnTypes) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes.headerCell}
            style={{
              maxWidth: headCell.minWidth,
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell
          key="action"
          align="center"
          className={classes.headerCell}
        ></TableCell>
      </TableRow>
    </TableHead>
  );
};
