import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  addToModifiedParams,
  setModifiedParamsValidity,
} from "../../catalog-editing-page-actions";
import {
  getEditObjData,
  getModifiedParams,
} from "../../catalog-editing-page-selectors";
import { FertiliserEditForm } from "./fertiliser-edit-form";
import {
  getUniqTypeFertileser,
  getUniqUnit,
} from "./fertiliser-edit-form-selectors";

const mapStateToProps = createStructuredSelector({
  editObjData: getEditObjData,
  uniqUnit: getUniqUnit,
  uniqTypeFertileser: getUniqTypeFertileser,
  modifiedParams: getModifiedParams,
});

const mapDispatchToProps = {
  addToModifiedParams,
  setModifiedParamsValidity,
};

export const FertiliserEditFormController = connect(
  mapStateToProps,
  mapDispatchToProps
)(FertiliserEditForm);
