import { makeStyles, Theme } from "@material-ui/core/styles";

export const useObservationCardStyles = makeStyles((theme: Theme) => ({
  img: {
    width: 100,
  },
  description: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    marginRight: 70,
  },
  textAliasesHeader: {
    lineHeight: "24px",
    fontSize: 12,
    color: "#808080",
  },
  textAliases: {
    lineHeight: "14px",
    fontSize: 14,
    marginBottom: 8,
  },
  textClass: {
    fontFamily: "Roboto-Medium, sans-serif",
    fontWeight: 600,
    lineHeight: "14px",
    fontSize: 14,
  },
}));
