import { createMuiTheme } from "@material-ui/core/styles";
import { overrides } from "./overrides";
import { palette } from "./palette";
import { typography } from "./typography";

export const MuiTheme = createMuiTheme({
  palette: palette,
  typography: typography,
  overrides: overrides,
});
