import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  addToModifiedParams,
  setModifiedParamsValidity,
} from "../../catalog-editing-page-actions";
import {
  getEditObjData,
  getModifiedParams,
} from "../../catalog-editing-page-selectors";
import { SeedEditForm } from "./seed-edit-form";
import { getUniqCropType, getUniqVariety } from "./seed-edit-form-selectors";

const mapStateToProps = createStructuredSelector({
  editObjData: getEditObjData,
  uniqCropType: getUniqCropType,
  uniqVariety: getUniqVariety,
  modifiedParams: getModifiedParams,
});

const mapDispatchToProps = {
  addToModifiedParams,
  setModifiedParamsValidity,
};

export const SeedEditFormController = connect(
  mapStateToProps,
  mapDispatchToProps
)(SeedEditForm);
