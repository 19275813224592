import { createSelector } from "reselect";
import {
  getInfoPositions,
  getInfoWorkPlace,
  getInfoFarms,
} from "src/modules/info-data";
import { CATALOG_PAGE_KEY } from "src/pages/catalog-page";
import { CATALOG_EDITING_PAGE_KEY } from "../..";
import { CatalogEditingPageState } from "../../catalog-editing-page-types";

const getState = (appState: any) =>
  appState[CATALOG_PAGE_KEY][CATALOG_EDITING_PAGE_KEY];

export const getUniqPosition = createSelector(getInfoPositions, (data: any) =>
  data
    .map((item: { name: string }) => item.name)
    .filter(
      (value: any, index: any, self: string | any[]) =>
        self.indexOf(value) === index
    )
    .sort()
);

export const getUniqWorkPlace = createSelector(getInfoWorkPlace, (data: any) =>
  data
    .map((item: { name: string }) => item.name)
    .filter(
      (value: any, index: any, self: string | any[]) =>
        self.indexOf(value) === index
    )
    .sort()
);

export const getUniqFarm = createSelector(getInfoFarms, (farms: any) =>
  farms
    ? farms
        .filter((farm: any) => farm.holdingId)
        .map((farm: { name: string }) => farm.name)
        .filter(
          (name: any, index: any, self: string | string[]) =>
            self.indexOf(name) === index
        )
        .sort()
    : null
);

export const getIsReadonly = createSelector(
  getState,
  (state: CatalogEditingPageState): boolean =>
    (state.objData && state.objData["appUserId"]) as boolean
);
