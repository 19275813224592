import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  fetchTechOperationsData,
  // fetchTechOperationsDataAsset,
  deleteObjData,
  setSelectedRows,
  setEndpoints,
  setModify,
} from "./tech-operations-page-actions";
import { TechOperationsComponent } from "./tech-operations-page";
import {
  getCurrentSeason,
  getSelectedRows,
  getTechOperationsData,
} from "./tech-operations-page-selectors";
import { TechOperationsEditingComponent } from "./tech-operations-editing-page";
import { getSelectedPeriod } from "./export-data/export-data-selectors";
import {
  getInfoCrops,
  getInfoTechOperationGroupName,
  getAppUserFarmLands,
} from "src/modules/info-data";

const mapStateToProps = createStructuredSelector({
  data: getTechOperationsData,
  selectedRows: getSelectedRows,
  selectedPeriod: getSelectedPeriod,
  crops: getInfoCrops,
  techOperationGroup: getInfoTechOperationGroupName,
  appUserFarms: getAppUserFarmLands,
  curSeason: getCurrentSeason,
});

const mapDispatchToProps = {
  fetchTechOperationsData,
  // fetchTechOperationsDataAsset,
  setSelectedRows,
  deleteObjData,
  setEndpoints,
  setModify,
};

export const TechOperationsPageController = connect(
  mapStateToProps,
  mapDispatchToProps
)(TechOperationsComponent);

export const TechOperationsEditingPageController = connect(
  mapStateToProps,
  mapDispatchToProps
)(TechOperationsEditingComponent);
