import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { setSelectedRows, setEndpoints } from "../catalog-page-actions";
import {
  fetchEditObjData,
  patchEditObjData,
  postObjData,
  clearEditObjData,
} from "./catalog-editing-page-actions";
import {
  getEditObjData,
  getIsFormModified,
  getModifiedParams,
  getModify,
} from "./catalog-editing-page-selectors";
import { CatalogEditingPage } from "./catalog-editing-page";
import { getEndpoints } from "../catalog-page-selectors";

const mapStateToProps = createStructuredSelector({
  modify: getModify,
  objData: getEditObjData,
  modifiedParams: getModifiedParams,
  endpoints: getEndpoints,
  isFormModified: getIsFormModified,
});

const mapDispatchToProps = {
  fetchEditObjData,
  patchEditObjData,
  postObjData,
  clearEditObjData,
  setSelectedRows,
  setEndpoints,
};

export const CatalogEditingPageController = connect(
  mapStateToProps,
  mapDispatchToProps
)(CatalogEditingPage);
