import { createSelector } from "reselect";
import { CATALOG_PAGE_KEY } from "..";
import { CATALOG_EDITING_PAGE_KEY } from "./catalog-editing-page-constants";
import { CatalogEditingPageState } from "./catalog-editing-page-types";

const getState = (appState: any) =>
  appState[CATALOG_PAGE_KEY][CATALOG_EDITING_PAGE_KEY];

// const getStateCatalog = (appState: any) => appState[CATALOG_PAGE_KEY];
// const getStateInfoData = (appState: any) => appState[INFO_DATA_KEY];

export const getModify = createSelector(
  getState,
  (state: CatalogEditingPageState) => state.modify
);

export const getEditObjData = createSelector(
  getState,
  (state: CatalogEditingPageState) => state.objData
);

export const getModifiedParams = createSelector(
  getState,
  (state: CatalogEditingPageState) => state.modifiedParams
);

export const getModifiedParamsValidity = createSelector(
  getState,
  (state: CatalogEditingPageState) => {
    const paramsAreNotModified = !Object.keys(state.modifiedParams).length;
    const isEditMode = state.modify === "edit";
    const defaultValidity = isEditMode ? true : false;

    return paramsAreNotModified
      ? defaultValidity
      : state.areValidModifiedParams;
  }
);

export const getIsReadonly = createSelector(
  getState,
  (state: CatalogEditingPageState): boolean =>
    (state.objData &&
      state.objData.hasOwnProperty("is_edit_by_app") &&
      !state.objData["is_edit_by_app"]) as boolean
);

export const getIsFormModified = createSelector(
  getState,
  (state: CatalogEditingPageState): boolean =>
    Object.keys(state.modifiedParams).length !== 0
);

// export const getInfoData = createSelector(
//   getStateCatalog,
//   (state: CatalogPageState) => state.info_data
// );
