import { createSelector } from "reselect";
import { getCatalogData } from "src/pages/catalog-page/catalog-page-selectors";

export const getUniqType = createSelector(getCatalogData, (data: any) =>
  data
    .map((item: { type: string }) => item.type)
    .filter(
      (value: any, index: any, self: string | any[]) =>
        self.indexOf(value) === index
    )
    .sort()
);

export const getUniqUnit = createSelector(getCatalogData, (data: any) =>
  data
    .map((item: { unit: string }) => item.unit)
    .filter(
      (value: any, index: any, self: string | any[]) =>
        self.indexOf(value) === index
    )
    .sort()
);
