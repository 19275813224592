import { makeStyles } from "@material-ui/core/styles";
import { SELECTED_BLUE_COLOR } from "src/styles/mui-theme/mui-theme-constants";

export const tableStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    boxShadow: "none",
  },
  container: {
    maxHeight: 610,
    height: 610,
  },
  tableCell: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    lineHeight: "1.14",
    fontSize: "13px",
    padding: "13px 4px 13px 16px",
  },
  editIcon: {
    "&:hover": {
      color: SELECTED_BLUE_COLOR,
    },
  },
  deleteIcon: {
    "&:hover": {
      color: "red",
    },
  },
}));
