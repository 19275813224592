import React from "react";
import { LoginFormDataTypes } from "src/common-types";
import { authContext } from "./authContext";
import { checkAccessTokenAPI } from "./checkAccessToken";
import { API_URL } from "../config/api";

export const ProvideAuth = ({ children }: any) => {
  const auth = useProvideAuth();

  if (auth.isAuthenticated === null) {
    let status = checkAccessTokenAPI();
    status.then((result) => {
      result
        ? auth.userHasAuthenticated(true)
        : auth.userHasAuthenticated(false);
    });
    return null;
  }

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

function useProvideAuth() {
  const [isAuthenticated, userHasAuthenticated] = React.useState<
    boolean | null
  >(null);

  const login = async (data: LoginFormDataTypes) => {
    const url = new URL(`${API_URL}/AppUsers/login`);

    try {
      let response = await fetch(url.toString(), {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });

      const jsonResponse = await response.json();
      console.info(jsonResponse);
      debugger;
      if (jsonResponse.id) {
        localStorage.setItem("access_token", jsonResponse.id);
        localStorage.setItem("user_id", jsonResponse.userId);
        userHasAuthenticated(true);
      }

      return jsonResponse.error ? false : true;
    } catch {
      console.log(`Ошибка получения токена.`);
      return false;
    }
  };

  const logout = async () => {
    // TODO
    let userId = localStorage["user_id"];
    let tokenAccess = localStorage["access_token"];

    const url = new URL(
      `${API_URL}/AppUsers/${userId}/${tokenAccess}?=access_token${tokenAccess}`
    );

    try {
      let response = await fetch(url.toString(), {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      });

      if (response.status === 204) {
        localStorage.removeItem("access_token");
        localStorage.removeItem("user_id");
        userHasAuthenticated(false);
      }

      return true; // TODO
    } catch {
      console.log(`Ошибка удаления токена.`);
      return false;
    }
  };

  return {
    login,
    logout,
    isAuthenticated,
    userHasAuthenticated,
  };
}
