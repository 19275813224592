import {
  SET_CURRENT_SEASON,
  SET_EXCEL_DATA,
  SET_MODIFY,
  SET_SELECTED_PERIOD,
  SET_TECHOPERATIONS_DATA,
} from "./tech-operations-page-actions";
import { TECHOPERATIONS_INITIAL_STATE } from "./tech-operations-page-constants";

export const techOperationsReducer = (
  state = TECHOPERATIONS_INITIAL_STATE,
  action: any
) => {
  const { type, payload } = action;
  switch (type) {
    case SET_TECHOPERATIONS_DATA:
      return {
        ...state,
        data: payload,
      };
    case SET_EXCEL_DATA:
      return {
        ...state,
        excel_data: payload,
      };
    case SET_SELECTED_PERIOD:
      return {
        ...state,
        selectedPeriod: payload,
      };
    case SET_CURRENT_SEASON:
      return {
        ...state,
        curSeasonId: payload,
      };
    case SET_MODIFY:
      return {
        ...state,
        modify: payload,
      };
    default:
      return state;
  }
};
