import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { addToModifiedParams } from "../../catalog-editing-page-actions";
import { getEditObjData } from "../../catalog-editing-page-selectors";
import { PhenophaseEditForm } from "./phenophase-edit-form";

const mapStateToProps = createStructuredSelector({
  editObjData: getEditObjData,
});

const mapDispatchToProps = {
  addToModifiedParams,
};

export const PhenophaseEditFormController = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhenophaseEditForm);
