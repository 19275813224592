import { EditFormComponent } from "src/components/edit-form";
import { TECHNICS_EDIT_LIST_ITEMS } from "src/config/edit-form";
import { ITechnicEditFormProps } from "./technic-edit-form-types";

export const TechnicEditForm = (props: ITechnicEditFormProps) => {
  const {
    editObjData,
    addToModifiedParams,
    setModifiedParamsValidity,
    modifiedParams,
    uniqTypes,
    uniqBrands,
    uniqModels,
    uniqFarm,
    uniqOwner,
    uniqtechOperationGroupName,
    uniqtechOperationSubGroupName,
    empty,
    techAssetCategory,
  } = props;

  const listItemsFunc = {
    techTypeName: uniqTypes,
    importTechBrand: uniqBrands,
    techModel: uniqModels,
    importFarmName: uniqFarm,
    techOwner: uniqOwner,
    techOperationGroupName: uniqtechOperationGroupName,
    techOperationSubGroupName: uniqtechOperationSubGroupName,
    importTechCategory: techAssetCategory,
  };

  return (
    <EditFormComponent
      editName="Техника"
      listItemsProp={TECHNICS_EDIT_LIST_ITEMS}
      listItemsFunc={listItemsFunc}
      editObjData={editObjData}
      addToModifiedParams={addToModifiedParams}
      empty={empty}
      setModifiedParamsValidity={setModifiedParamsValidity}
      modifiedParams={modifiedParams}
    />
  );
};
