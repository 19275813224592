import React from "react";
import { Link } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Checkbox,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import EditIcon from "@material-ui/icons/Edit";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Sidebar } from "src/modules/sidebar";
import { useObservationPageStyles } from "./observation-page-styles";
import { ObservationCardComponent } from "./observation-card";

export const ObservationsComponent = (props: any) => {
  const {
    fetchObservationsData,
    setSelectedPeriod,
    setCurrentSeason,
    data,
    selectedRows,
    setSelectedRows,
    selectedPeriod,
    seasons,
    curSeason,
  } = props;

  const classes = useObservationPageStyles();

  const [page, setPage] = React.useState(1);
  const rowsPerPage = 20;
  let [loaderData, setLoaderData] = React.useState<boolean>(true);

  React.useEffect(() => {
    fetchObservationsData();
    setLoaderData(true);
  }, [selectedPeriod, curSeason, fetchObservationsData]);

  React.useEffect(() => {
    setLoaderData(false);
  }, [data]);

  if (!data) return null;
  const pageCount = Math.ceil(data.length / rowsPerPage);

  const handleChangePage = (
    event: any,
    newPage: React.SetStateAction<number>
  ) => {
    setPage(newPage);
  };

  const handleCheckboxClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    const selectedIndex = selectedRows.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }
    setSelectedRows(newSelected);
  };

  const handleDateFromChange = (date: Date | null) => {
    setSelectedPeriod({
      ...selectedPeriod,
      dateFrom: date ? date.getTime() : null,
    });
  };
  const handleDateToChange = (date: Date | null) => {
    setSelectedPeriod({
      ...selectedPeriod,
      dateTo: date ? date.getTime() : null,
    });
  };

  // const [curSeason, setCurSeason] = React.useState("");

  const changeSeason = (event) => {
    setCurrentSeason(event.target.value);
  };

  if (!seasons) return null;
  let listSeason = seasons.sort((a, b) => (a.year < b.year && 1) || -1);

  return (
    <div className={classes.root}>
      <Sidebar title="Отчёты осмотров" sidebarIndex={2} />

      <main className={classes.content}>
        <div className={classes.toolbar} />

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container>
            <KeyboardDatePicker
              autoOk
              className={classes.datapicker}
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="От"
              value={selectedPeriod.dateFrom}
              onChange={handleDateFromChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
            <KeyboardDatePicker
              autoOk
              className={classes.datapicker}
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline1"
              label="до"
              value={selectedPeriod.dateTo}
              onChange={handleDateToChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
            <FormControl>
              <InputLabel id="demo-simple-select-label">Сезон</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={curSeason}
                onChange={changeSeason}
                style={{ minWidth: "100px" }}
              >
                {listSeason
                  .map((index) => (
                    <MenuItem value={index.id}>{index.name}</MenuItem>
                  ))
                  .sort((a, b) => (a.year > b.year && 1) || -1)}
              </Select>
            </FormControl>
          </Grid>
        </MuiPickersUtilsProvider>

        <List dense className={classes.root1}>
          {data
            .slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage)
            .map((item: any) => {
              // const labelId = `checkbox-list-secondary-label-${item.importId}`;
              return (
                <ListItem key={item.id} className={classes.rowItem}>
                  {/* <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={selectedRows.indexOf(item.id) !== -1}
                      onChange={(event) => handleCheckboxClick(event, item.id)}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon> */}

                  <ObservationCardComponent filterData={item} />
                </ListItem>
              );
            })}
        </List>

        <div className={classes.pagination}>
          <Pagination
            page={page}
            count={pageCount}
            onChange={handleChangePage}
          />
        </div>
      </main>

      <div
        style={{
          height: "100%",
          width: "100%",
          background: "rgb(128 128 128 / 24%)",
          position: "absolute",
          top: 0,
          zIndex: 2000,
          paddingTop: "30%",
          display: loaderData ? "block" : "none",
        }}
      >
        <CircularProgress
          style={{
            marginLeft: "50%",
            marginRight: "50%",
          }}
        />
      </div>
    </div>
  );
};
