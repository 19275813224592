import { excelFieldsTypes } from "./export-data-types";

export const EXCEL_NAME = "Techoperations";

export const EXCEL_DEFAULT = [
  {
    columns: [{ title: "" }],
    data: [{ value: "" }],
  },
];

export const EXCEL_TITLE_STYLE = {
  font: { bold: true },
  // fill: { patternType: "solid", fgColor: { rgb: "dfdede" } },
  alignment: { vertical: "center", horizontal: "center", wrapText: true },
  // border: {
  //   right: { style: "medium", color: { rgb: "000000" } },
  // },
};

export const EXCEL_FIELDS: excelFieldsTypes[] = [
  {
    title: "Сезон",
    part: "season",
    value: "name",
    tillage: true,
    fertilizing: true,
    sowing: true,
    spraying: true,
    productionFeed: true,
    productionCommercial: true,
    width: { wpx: 60 },
    style: { alignment: { vertical: "center", horizontal: "right" } },
  },
  {
    title: "Хозяйство",
    part: "farm",
    value: "name",
    tillage: true,
    fertilizing: true,
    sowing: true,
    spraying: true,
    productionFeed: true,
    productionCommercial: true,
    width: { wpx: 140 },
    style: { alignment: { vertical: "center", horizontal: "left" } },
  },
  {
    title: "Отделение",
    part: "department",
    value: "name",
    tillage: true,
    fertilizing: true,
    sowing: true,
    spraying: true,
    productionFeed: true,
    productionCommercial: true,
    width: { wpx: 140 },
    style: { alignment: { vertical: "center", horizontal: "left" } },
  },
  {
    title: "№ поля",
    part: "farmLand",
    value: "name",
    tillage: true,
    fertilizing: true,
    sowing: true,
    spraying: true,
    productionFeed: true,
    productionCommercial: true,
    width: { wpx: 150 },
    style: { alignment: { vertical: "center", horizontal: "left" } },
  },
  {
    title: "Площадь поля, га",
    part: "area",
    value: "name",
    tillage: true,
    fertilizing: true,
    sowing: true,
    spraying: true,
    productionFeed: true,
    productionCommercial: true,
    width: { wpx: 70 },
    style: { alignment: { vertical: "center", horizontal: "left" } },
  },
  {
    title: "Культура - текущий сезон",
    part: "crop",
    tillage: true,
    fertilizing: true,
    sowing: true,
    spraying: true,
    productionFeed: true,
    productionCommercial: true,
    value: "name",
    width: { wpx: 140 },
    style: { alignment: { vertical: "center", horizontal: "left" } },
  },
  // {
  //   title: "Культура - предшественник",
  //   part: "lastCrop",
  //   value: "name",
  //   width: { wpx: 140 },
  //   style: { alignment: { vertical: "center", horizontal: "left" } },
  // },
  {
    title: "Дата начала работ",
    part: "startedAt",
    tillage: true,
    fertilizing: true,
    sowing: true,
    spraying: true,
    productionFeed: true,
    productionCommercial: true,
    width: { wpx: 90 },
    style: { alignment: { vertical: "center", horizontal: "right" } },
  },
  {
    title: "Дата завершения работ",
    part: "finishedAt",
    tillage: true,
    fertilizing: true,
    sowing: true,
    spraying: true,
    productionFeed: true,
    productionCommercial: true,
    width: { wpx: 90 },
    style: { alignment: { vertical: "center", horizontal: "right" } },
  },
  {
    title: "Подгруппа техоперации",
    part: "techOperationSubGroup",
    tillage: true,
    fertilizing: true,
    sowing: true,
    spraying: true,
    productionFeed: true,
    productionCommercial: true,
    width: { wpx: 240 },
    style: { alignment: { vertical: "center", horizontal: "left" } },
  },
  {
    title: "Площадь проведенных работ, га",
    part: "fieldSize",
    tillage: true,
    fertilizing: true,
    sowing: true,
    spraying: true,
    productionFeed: true,
    productionCommercial: true,
    width: { wpx: 100 },
    style: { alignment: { vertical: "center", horizontal: "right" } },
  },
  {
    title: "Трактор/Самоходная техника",
    part: "techAsset",
    tillage: true,
    fertilizing: true,
    sowing: true,
    spraying: true,
    productionFeed: true,
    productionCommercial: true,
    width: { wpx: 310 },
    style: { alignment: { vertical: "center", horizontal: "left" } },
  },
  {
    title: "RFID-метка Трактора/ Самаходной техники",
    part: "techAssetRFID",
    tillage: true,
    fertilizing: true,
    sowing: true,
    spraying: true,
    productionFeed: true,
    productionCommercial: true,
    width: { wpx: 110 },
    style: { alignment: { vertical: "center", horizontal: "right" } },
  },
  {
    title: "С/Х машина",
    part: "trailerAsset",
    tillage: true,
    fertilizing: true,
    sowing: true,
    spraying: true,
    productionFeed: true,
    productionCommercial: true,
    width: { wpx: 310 },
    style: { alignment: { vertical: "center", horizontal: "left" } },
  },
  {
    title: "RFID-метка С/Х машины",
    part: "trailerAssetRFID",
    tillage: true,
    fertilizing: true,
    sowing: true,
    spraying: true,
    productionFeed: true,
    productionCommercial: true,
    width: { wpx: 110 },
    style: { alignment: { vertical: "center", horizontal: "right" } },
  },
  {
    title: "Механизатор",
    part: "employee",
    tillage: true,
    fertilizing: true,
    sowing: true,
    spraying: true,
    productionFeed: true,
    productionCommercial: true,
    width: { wpx: 210 },
    style: { alignment: { vertical: "center", horizontal: "left" } },
  },
  {
    title: "Агроном",
    part: "appUser",
    tillage: true,
    fertilizing: true,
    sowing: true,
    spraying: true,
    productionFeed: true,
    productionCommercial: true,
    width: { wpx: 210 },
    style: { alignment: { vertical: "center", horizontal: "left" } },
  },
  {
    title: "Глубина обработки, см",
    part: "processingDepth",
    tillage: true,
    fertilizing: false,
    sowing: false,
    spraying: false,
    productionFeed: false,
    productionCommercial: false,
    width: { wpx: 70 },
    style: { alignment: { vertical: "center", horizontal: "left" } },
  },
  {
    title: "Наименование семян",
    part: "seedName",
    tillage: false,
    fertilizing: false,
    sowing: true,
    spraying: false,
    productionFeed: false,
    productionCommercial: false,
    width: { wpx: 210 },
    style: { alignment: { vertical: "center", horizontal: "left" } },
  },
  {
    title: "Наименование удобрений",
    part: "fertiliserName",
    tillage: true,
    fertilizing: true,
    sowing: true,
    spraying: true,
    productionFeed: false,
    productionCommercial: false,
    width: { wpx: 210 },
    style: { alignment: { vertical: "center", horizontal: "left" } },
  },
  {
    title: "Количество внесенного удобрения",
    part: "quantity",
    tillage: true,
    fertilizing: true,
    sowing: true,
    spraying: true,
    productionFeed: false,
    productionCommercial: false,
    width: { wpx: 90 },
    style: { alignment: { vertical: "center", horizontal: "left" } },
  },
  {
    title: "Ед. изм.",
    part: "unit",
    tillage: true,
    fertilizing: true,
    sowing: true,
    spraying: true,
    productionFeed: false,
    productionCommercial: false,
    width: { wpx: 40 },
    style: { alignment: { vertical: "center", horizontal: "left" } },
  },
  {
    title: "Норма внесения удобрений, ед.изм./га",
    part: "assetRateMin",
    tillage: true,
    fertilizing: true,
    sowing: true,
    spraying: true,
    productionFeed: false,
    productionCommercial: false,
    width: { wpx: 90 },
    style: { alignment: { vertical: "center", horizontal: "left" } },
  },
  {
    title: "Влажность при уборке (%)",
    part: "humidity",
    tillage: false,
    fertilizing: false,
    sowing: false,
    spraying: false,
    productionFeed: false,
    productionCommercial: true,
    width: { wpx: 110 },
    style: { alignment: { vertical: "center", horizontal: "left" } },
  },
  {
    title: "Сорность/загрязненность при уборке (%)",
    part: "impurity",
    tillage: false,
    fertilizing: false,
    sowing: false,
    spraying: false,
    productionFeed: false,
    productionCommercial: true,
    width: { wpx: 110 },
    style: { alignment: { vertical: "center", horizontal: "left" } },
  },
  {
    title: "Валовый сбор при базовой влажности и сорности/загрязненности, т",
    part: "harvestedAmount",
    tillage: false,
    fertilizing: false,
    sowing: false,
    spraying: false,
    productionFeed: false,
    productionCommercial: true,
    width: { wpx: 110 },
    style: { alignment: { vertical: "center", horizontal: "left" } },
  },
  {
    title: "Урожайность при уборочной влажности и сорной примеси, ц/га",
    part: "productivityCommercial",
    tillage: false,
    fertilizing: false,
    sowing: false,
    spraying: false,
    productionFeed: false,
    productionCommercial: true,
    width: { wpx: 110 },
    style: { alignment: { vertical: "center", horizontal: "left" } },
  },
  {
    title: "Сухое вещество (СВ), (%)",
    part: "dryPercent",
    tillage: false,
    fertilizing: false,
    sowing: false,
    spraying: false,
    productionFeed: true,
    productionCommercial: false,
    width: { wpx: 110 },
    style: { alignment: { vertical: "center", horizontal: "left" } },
  },
  {
    title: "Валовый сбор, т",
    part: "grossYieldHumidity",
    tillage: false,
    fertilizing: false,
    sowing: false,
    spraying: false,
    productionFeed: true,
    productionCommercial: false,
    width: { wpx: 110 },
    style: { alignment: { vertical: "center", horizontal: "left" } },
  },
  {
    title: "Валовый сбор СВ, т",
    part: "dryGrossYield",
    tillage: false,
    fertilizing: false,
    sowing: false,
    spraying: false,
    productionFeed: true,
    productionCommercial: false,
    width: { wpx: 110 },
    style: { alignment: { vertical: "center", horizontal: "left" } },
  },
  {
    title: "Урожайность, ц/га",
    part: "harvestingHumidity",
    tillage: false,
    fertilizing: false,
    sowing: false,
    spraying: false,
    productionFeed: true,
    productionCommercial: false,
    width: { wpx: 110 },
    style: { alignment: { vertical: "center", horizontal: "left" } },
  },
  {
    title: "Урожайность СВ, ц/га",
    part: "dryHarvesting",
    tillage: false,
    fertilizing: false,
    sowing: false,
    spraying: false,
    productionFeed: true,
    productionCommercial: false,
    width: { wpx: 110 },
    style: { alignment: { vertical: "center", horizontal: "left" } },
  },
];
