// import { Dispatch } from "redux";
import { Util } from "src/utils/utils";
import { ThunkDispatch } from "redux-thunk";
import { OBSERVATIONS_PAGE_KEY } from "./observation-page-constants";

const OBSERVATIONS_NAMESPACE = "@observationPage";

export const SET_OBSERVATIONS_DATA = `${OBSERVATIONS_NAMESPACE}/SET_OBSERVATIONS_DATA`;
export const setObservationsData = (data: any[]) => ({
  type: SET_OBSERVATIONS_DATA,
  payload: data,
});

export const fetchObservationsData =
  () => async (dispatch: ThunkDispatch<{}, {}, any>, getState) => {
    // const { [OBSERVATIONS_PAGE_KEY]: pageState } = getState();
    const { [OBSERVATIONS_PAGE_KEY]: pageState } = getState();
    if (!pageState.curSeasonId || !pageState.selectedPeriod) return;

    // if (!pageState) return;

    const fetchData = await Util.getFeaturesAPI(
      "Observations",
      undefined,
      undefined,
      500,
      pageState.selectedPeriod,
      undefined,
      undefined,
      undefined,
      pageState.curSeasonId,
      true
    );

    dispatch(setObservationsData(fetchData));
  };

export const SET_SELECTED_ROWS = `${OBSERVATIONS_NAMESPACE}/SET_SELECTED_ROWS`;
export const setSelectedRows = (rows: string[]) => ({
  type: SET_SELECTED_ROWS,
  payload: rows,
});

export const SET_SELECTED_PERIOD = `${OBSERVATIONS_NAMESPACE}/SET_SELECTED_PERIOD`;
export const setSelectedPeriod = (period: {
  dateFrom: Date;
  dateTo: Date;
}) => ({
  type: SET_SELECTED_PERIOD,
  payload: period,
});

export const SET_CURRENT_SEASON = `${OBSERVATIONS_NAMESPACE}/SET_CURRENT_SEASON`;
export const setCurrentSeason = (season) => ({
  type: SET_CURRENT_SEASON,
  payload: season,
});
