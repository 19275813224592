import React from "react";
import ReactExport from "react-data-export";
import { ExcelExportDataTypes } from "src/common-types";
import GetAppIcon from "@material-ui/icons/GetApp";
import "./download-excel.scss";
// import { fetchTechOperationsDataAsset } from "src/pages/tech-operations-page/tech-operations-page-actions";
import { getByDisplayValue } from "@testing-library/react";

type dataExcelType = {
  tillage: ExcelExportDataTypes[];
  fertilizing: ExcelExportDataTypes[];
  sowing: ExcelExportDataTypes[];
  spraying: ExcelExportDataTypes[];
  productionFeed: ExcelExportDataTypes[];
  productionCommercial: ExcelExportDataTypes[];
};

interface DownloadExcelBtnProps {
  btnName: string;
  excelName: string;
  // data: ExcelExportDataTypes[];
  data: dataExcelType;
  addCurrentTime?: boolean;
  sheetName: string;
  onClickGetData: () => void;
}

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

function getCurrentTime() {
  const today = new Date();

  let day: string | number = today.getDate();
  if (day < 10) day = "0" + day;

  let month: string | number = today.getMonth() + 1;
  if (month < 10) month = "0" + month;

  let year: string | number = today.getFullYear() % 100;
  if (year < 10) year = "0" + year;

  let hour: string | number = today.getHours();
  if (hour < 10) hour = "0" + hour;

  let minutes: string | number = today.getMinutes();
  if (minutes < 10) minutes = "0" + minutes;

  let seconds: string | number = today.getSeconds();
  if (seconds < 10) seconds = "0" + seconds;

  return "" + day + month + year + "_" + hour + minutes + seconds;
}

export const DownloadExcelBtn = (props: DownloadExcelBtnProps) => {
  const {
    btnName,
    excelName,
    data,
    addCurrentTime,
    sheetName,
    onClickGetData,
  } = props;

  console.info(data);

  const onClick = () => {
    console.info("onClick");
    // setDisableBtn(true);
    setDataExcel(true);
    onClickGetData();
  };

  React.useEffect(() => {
    console.info("React.useEffect");
    setDataExcel(false);
    // setDisableBtn(false);
  }, [data]);

  // let [disableBtn, setDisableBtn] = React.useState(false);
  let [dataExcel, setDataExcel] = React.useState(false);
  // if (!data) return;

  return (
    <>
      <div
        // className={
        //   disableBtn ? "btn-download-excel block" : "btn-download-excel"
        // }
        className="btn-download-excel"
        onClick={onClick}
      >
        <GetAppIcon />
        {btnName}
      </div>
      {dataExcel && data ? (
        <ExcelFile
          filename={
            addCurrentTime ? excelName + "_" + getCurrentTime() : excelName
          }
          hideElement={true}
          // element={
          //   <div className="btn-download-excel" onClick={onClickGetData}>
          //     {btnName}
          //     <GetAppIcon />
          //   </div>
          // }
        >
          <ExcelSheet dataSet={data.tillage} name={sheetName} />
          <ExcelSheet
            dataSet={
              data.fertilizing === [] ? [{ value: "" }] : data.fertilizing
            }
            name="Внесение удобрений"
          />
          <ExcelSheet
            dataSet={data.sowing === [] ? [{ value: "" }] : data.sowing}
            name="Сев"
          />
          <ExcelSheet
            dataSet={data.spraying === [] ? [{ value: "" }] : data.spraying}
            name="СЗP"
          />
          <ExcelSheet dataSet={data.productionFeed} name="Уборка_Корм" />
          <ExcelSheet dataSet={data.productionCommercial} name="Уборка_Товар" />
        </ExcelFile>
      ) : null}
    </>
  );
};
