import { EditFormTypes } from "src/common-types";
import {
  NAMING_VALIDATION_PATTERN,
  EMAIL_VALIDATION_PATTERN,
  PHONE_NUMBER_VALIDATION_PATTERN,
} from "./validation-constants";

export const APPUSERS_EDIT_LIST_ITEMS: EditFormTypes[] = [
  {
    id: "lastName",
    label: "Фамилия: ",
    inputs: [{ type: "string", placeholder: "Фамилия" }],
    isRequired: true,
    validationPatterns: NAMING_VALIDATION_PATTERN,
  },
  {
    id: "firstName",
    label: "Имя: ",
    inputs: [{ type: "string", placeholder: "Имя" }],
    isRequired: true,
    validationPatterns: NAMING_VALIDATION_PATTERN,
  },
  {
    id: "middleName",
    label: "Отчество: ",
    inputs: [{ type: "string", placeholder: "Отчество" }],
    validationPatterns: NAMING_VALIDATION_PATTERN,
  },
  {
    id: "positionName",
    label: "Должность: ",
    inputs: [{ type: "dropdown", placeholder: "Должность" }],
    isRequired: true,
  },
  {
    id: "phone",
    label: "Телефон: ",
    inputs: [{ type: "string", placeholder: "Телефон" }],
    validationPatterns: PHONE_NUMBER_VALIDATION_PATTERN,
  },
  {
    id: "email",
    label: "E-mail: ",
    inputs: [{ type: "string", placeholder: "e-mail" }],
    isRequired: true,
    validationPatterns: EMAIL_VALIDATION_PATTERN,
  },
  {
    id: "importFarmName",
    label: "Доступные хозяйства: ",
    inputs: [{ type: "multiselect", placeholder: "Хозяйство" }],
    isRequired: true,
    relatedTypeId: "defaultFarmName",
  },
  {
    id: "defaultFarmName",
    label: "Хозяйство по умолчанию: ",
    inputs: [{ type: "dropdown", placeholder: "Хозяйство по умолчанию:" }],
    isRequired: true,
  },
  {
    id: "fullName",
    label: "Полное имя",
    inputs: [{ type: "string", placeholder: "Полное имя" }],
    isReadonly: true,
    compositionTemplate: {
      lastName: "",
      firstName: " ",
      middleName: " ",
    },
  },
];
