import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { Util } from "src/utils/utils";

const INFO_NAMESPACE = "@infoData";

//TODO any
export const SET_INFO_DATA = `${INFO_NAMESPACE}/SET_INFO_DATA`;
export const setInfoData = (info_data: any) => ({
  type: SET_INFO_DATA,
  payload: info_data,
});

export const fetchCatalogInfoData = () => async (dispatch: Dispatch) => {
  const fetchInfoAppUsers = await Util.getFeaturesAPI("AppUsers");
  const fetchInfoDataChemicals = await Util.getFeaturesAPI("Chemicals");
  const fetchInfoDataCrops = await Util.getFeaturesAPI("Crops");
  const fetchInfoDataCropTypes = await Util.getFeaturesAPI("CropTypes");
  const fetchInfoDataDepartments = await Util.getFeaturesAPI("Departments");
  const fetchInfoDataEmployees = await Util.getFeaturesAPI("Employees");
  const fetchInfoDataFarms = await Util.getFeaturesAPI("Farms");
  const fetchInfoDataFertilisers = await Util.getFeaturesAPI("Fertilisers");
  const fetchInfoDataPositions = await Util.getFeaturesAPI("Positions");
  const fetchInfoDataSeasons = await Util.getFeaturesAPI("Seasons");
  const fetchInfoDataSeeds = await Util.getFeaturesAPI("Seeds");
  const fetchInfoDataTechAssets = await Util.getFeaturesAPI("TechAssets");
  const fetchInfoDataTechTypes = await Util.getFeaturesAPI("TechTypes");
  const fetchInfoDataTechOperationGroup = await Util.getFeaturesAPI(
    "TechOperationGroups"
  );
  const fetchInfoDataTechOperationSubGroup = await Util.getFeaturesAPI(
    "TechOperationSubGroups"
  );
  const fetchInfoDataWorkPlaces = await Util.getFeaturesAPI("WorkPlaces");

  dispatch(
    setInfoData({
      appUsers: fetchInfoAppUsers,
      chemicals: fetchInfoDataChemicals,
      crops: fetchInfoDataCrops,
      cropTypes: fetchInfoDataCropTypes,
      departments: fetchInfoDataDepartments,
      employees: fetchInfoDataEmployees,
      farms: fetchInfoDataFarms,
      fertilisers: fetchInfoDataFertilisers,
      positions: fetchInfoDataPositions,
      seasons: fetchInfoDataSeasons,
      seeds: fetchInfoDataSeeds,
      techAsset: fetchInfoDataTechAssets,
      techTypes: fetchInfoDataTechTypes,
      techOperationGroup: fetchInfoDataTechOperationGroup,
      techOperationSubGroup: fetchInfoDataTechOperationSubGroup,
      workPlace: fetchInfoDataWorkPlaces,
      techAssetCategory: ["Самоходная", "Прицепная"],
    })
  );
};

export const SET_INFO_DATA_FARMLAND = `${INFO_NAMESPACE}/SET_INFO_DATA_FARMLAND`;
export const setInfoDataFarmLand = (info_data: string[]) => ({
  type: SET_INFO_DATA_FARMLAND,
  payload: info_data,
});

export const fetchInfoDataFarmLands =
  () => async (dispatch: ThunkDispatch<{}, {}, any>) => {
    const fetchInfoData = await Util.getFeaturesAPI(
      "AppUsers",
      localStorage["user_id"],
      "farms"
    );
    // const fetchInfoData = await Util.getFeaturesAPI(
    //   "FarmLands",
    //   undefined,
    //   undefined,
    //   undefined,
    //   undefined,
    //   { id: true, name: true, area: true, cropId: true }
    // );
    dispatch(setInfoDataFarmLand(fetchInfoData));
  };
