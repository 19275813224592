import React from "react";
import Grid from "@material-ui/core/Grid";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  makeStyles,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@material-ui/core";
import { DownloadExcelBtn } from "src/components/buttons";
import { EXCEL_NAME } from "./export-data-constants";

// const excelData = [
//   {
//     columns: [
//       { title: "Headings", width: { wpx: 80 } }, //pixels width
//       { title: "Text Style", width: { wch: 40 } }, //char width
//       { title: "Colors", width: { wpx: 90 } },
//     ],
//     data: [
//       [{ value: "H2" }, { value: "underline" }, { value: "Blue" }],
//       [
//         { value: "H5", style: { font: { sz: "10.5", bold: true } } },
//         { value: "outline", style: { font: { outline: true } } },
//         {
//           value: "Yellow",
//         },
//       ],
//     ],
//   },
// ];

export const exportDataToExcelStyles = makeStyles((theme) => ({
  datapicker: {
    margin: "0 15px",
  },
  btnDownload: {
    flexGrow: 1,
  },
}));

export const ExportDataToExcel = (props: any) => {
  const {
    excelData,
    selectedPeriod,
    setSelectedPeriod,
    seasons,
    curSeason,
    setCurrentSeason,
    // fetchTechOperationsDataAsset,
  } = props;

  const classes = exportDataToExcelStyles();

  const handleDateFromChange = (date: Date | null) => {
    setSelectedPeriod({
      ...selectedPeriod,
      dateFrom: date ? date.getTime() : null,
    });
  };
  const handleDateToChange = (date: Date | null) => {
    setSelectedPeriod({
      ...selectedPeriod,
      dateTo: date ? date.getTime() : null,
    });
  };

  // const [curSeason, setCurSeason] = React.useState("");

  const changeSeason = (event) => {
    setCurrentSeason(event.target.value);
  };

  let listSeason = seasons.sort((a, b) => (a.year < b.year && 1) || -1);

  const onClickGetExcelData = () => {
    console.info("onClickGetExcelData");
    // fetchTechOperationsDataAsset();
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container>
        <KeyboardDatePicker
          autoOk
          className={classes.datapicker}
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          id="date-picker-inline"
          label="От"
          value={selectedPeriod.dateFrom}
          onChange={handleDateFromChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
        <KeyboardDatePicker
          autoOk
          className={classes.datapicker}
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          id="date-picker-inline1"
          label="до"
          value={selectedPeriod.dateTo}
          onChange={handleDateToChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
        <FormControl>
          <InputLabel id="demo-simple-select-label">Сезон</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={curSeason}
            onChange={changeSeason}
            style={{ minWidth: "100px" }}
          >
            {listSeason
              .map((index) => (
                <MenuItem value={index.id}>{index.name}</MenuItem>
              ))
              .sort((a, b) => (a.year > b.year && 1) || -1)}
          </Select>
        </FormControl>
        <div className={classes.btnDownload}>
          <DownloadExcelBtn
            btnName="Выгрузить техоперации"
            data={excelData}
            excelName={EXCEL_NAME}
            addCurrentTime={true}
            sheetName="Обработка почвы"
            onClickGetData={onClickGetExcelData}
          />
        </div>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};
