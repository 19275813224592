import {
  ADD_TO_MODIFIED_PARAMS,
  CLEAR_EDIT_OBJ_DATA,
  SET_EDIT_OBJ_ID,
  SET_EDIT_OBJ_DATA,
  SET_MODIFY,
  SET_MODIFIED_PARAMS_VALIDITY,
} from "./catalog-editing-page-actions";
import { CATALOG_EDITING_PAGE_INITIAL_STATE } from "./catalog-editing-page-constants";

export const catalogEditReducer = (
  state = CATALOG_EDITING_PAGE_INITIAL_STATE,
  action: any
) => {
  const { type, payload } = action;
  switch (type) {
    case SET_EDIT_OBJ_ID:
      return {
        ...state,
        objId: payload,
      };
    case SET_EDIT_OBJ_DATA:
      return {
        ...state,
        objData: payload,
      };
    case SET_MODIFY:
      return {
        ...state,
        modify: payload,
      };
    case SET_MODIFIED_PARAMS_VALIDITY:
      return {
        ...state,
        areValidModifiedParams: payload,
      };
    case ADD_TO_MODIFIED_PARAMS:
      return {
        ...state,
        modifiedParams: payload,
      };
    case CLEAR_EDIT_OBJ_DATA:
      return {
        ...state,
        objId: undefined,
        objData: undefined,
        modify: undefined,
        modifiedParams: {},
      };
    default:
      return state;
  }
};
