import { createSelector } from "reselect";
import {
  getInfoSeasons,
  getInfoFarms,
  getInfoDepartments,
  getInfoCrops,
  getInfoTechAssets,
  getInfoTechOperationSubGroupName,
  getInfoEmployees,
  getInfoAppUsers,
  getInfoTechOperationGroupName,
  getInfoFarmLands,
  getInfoChemicals,
  getInfoFertilisers,
  getStateInfoData,
} from "src/modules/info-data";
import { TECHOPERATIONS_PAGE_KEY } from "../tech-operations-page-constants";
import {
  EXCEL_DEFAULT,
  EXCEL_FIELDS,
  EXCEL_TITLE_STYLE,
} from "./export-data-constants";

const getState = (appState: any) => appState[TECHOPERATIONS_PAGE_KEY].data; //excel_data;
const getStateTechOper = (appState: any) => appState[TECHOPERATIONS_PAGE_KEY];

export const getSelectedPeriod = createSelector(
  getStateTechOper,
  (state: any) => state.selectedPeriod
);

function getFormateDate(unixtime) {
  let date = new Date(unixtime);
  let day: string | number = date.getDate();
  if (day < 10) day = "0" + day;

  let month: string | number = date.getMonth() + 1;
  if (month < 10) month = "0" + month;

  let year: string | number = date.getFullYear();
  if (year < 10) year = "0" + year;

  return day + "." + month + "." + year;
}

export const createGetExcelData = createSelector(
  getState,
  getStateInfoData,
  getInfoSeasons,
  getInfoFarms,
  getInfoDepartments,
  // getInfoChemicals,
  getInfoCrops,
  // getInfoFertilisers,
  getInfoTechAssets,
  getInfoTechOperationGroupName,
  getInfoTechOperationSubGroupName,
  getInfoEmployees,
  getInfoAppUsers,
  getInfoFarmLands,
  (
    techOperations,
    infoData,
    seasons,
    farms,
    departments,
    // chemicals,
    // fertilisers,
    crops,
    techAssets,
    techOperationGroups,
    techOperationSubGroups,
    employees,
    appUsers,
    farmLands
  ) => {
    if (!techOperations) return;
    if (
      !seasons ||
      !farms ||
      !departments ||
      !infoData ||
      !crops ||
      !techAssets ||
      !techOperationGroups ||
      !techOperationSubGroups ||
      !employees ||
      !appUsers ||
      !farmLands
    )
      return;

    const headerExcelTillage = EXCEL_FIELDS.filter(
      (field) => field.tillage
    ).map((field) => {
      const title = field.title;
      const width = field.width;
      return { style: EXCEL_TITLE_STYLE, title: title, width: width };
    });
    const headerExcelFertilizing = EXCEL_FIELDS.filter(
      (field) => field.fertilizing
    ).map((field) => {
      const title = field.title;
      const width = field.width;
      return { style: EXCEL_TITLE_STYLE, title: title, width: width };
    });
    const headerExcelSowing = EXCEL_FIELDS.filter((field) => field.sowing).map(
      (field) => {
        const title = field.title;
        const width = field.width;
        return { style: EXCEL_TITLE_STYLE, title: title, width: width };
      }
    );
    const headerExcelSpraying = EXCEL_FIELDS.filter(
      (field) => field.spraying
    ).map((field) => {
      const title = field.title;
      const width = field.width;
      return { style: EXCEL_TITLE_STYLE, title: title, width: width };
    });
    const headerExcelProductionFeed = EXCEL_FIELDS.filter(
      (field) => field.productionFeed
    ).map((field) => {
      const title = field.title;
      const width = field.width;
      return { style: EXCEL_TITLE_STYLE, title: title, width: width };
    });
    const headerExcelProductionCommercial = EXCEL_FIELDS.filter(
      (field) => field.productionCommercial
    ).map((field) => {
      const title = field.title;
      const width = field.width;
      return { style: EXCEL_TITLE_STYLE, title: title, width: width };
    });

    type dataExcelType = {
      [key: string]: {
        value: string | number;
      }[][];
    };

    let dataExcel: dataExcelType = {
      tillage: [],
      fertilizing: [],
      sowing: [],
      spraying: [],
      productionFeed: [],
      productionCommercial: [],
    };

    techOperations.map((oper) => {
      // let techOperationId: string = oper.seasonId;
      let season = seasons.find((x) => x.id === oper.seasonId)
        ? seasons.find((x) => x.id === oper.seasonId).name
        : "";
      let farm = farms.find((x) => x.id === oper.farmId)
        ? farms.find((x) => x.id === oper.farmId).name
        : "";
      let department = departments.find((x) => x.id === oper.departmentId)
        ? departments.find((x) => x.id === oper.departmentId).name
        : "";

      let farmLand,
        area,
        crop = "";
      if (farmLands.find((x) => x.id === oper.farmLandId)) {
        farmLand = farmLands.find((x) => x.id === oper.farmLandId).name;
        area = farmLands.find((x) => x.id === oper.farmLandId).area;
        let cropId = farmLands.find((x) => x.id === oper.farmLandId).cropId;
        // crop = crops.find((x) => x.id === cropId).name;
        crop = crops.find((x) => x.id === cropId)
          ? crops.find((x) => x.id === cropId).name
          : "";
      }

      // let crop = crops.find((x) => x.id === oper.cropId)
      //   ? crops.find((x) => x.id === oper.cropId).name
      //   : "";

      let techAsset = techAssets.find((x) => x.id === oper.techAssetId)
        ? techAssets.find((x) => x.id === oper.techAssetId).name
        : "";
      let techAssetRFID = techAssets.find((x) => x.id === oper.techAssetId)
        ? techAssets.find((x) => x.id === oper.techAssetId).rfid
        : "";
      let trailerAsset = techAssets.find((x) => x.id === oper.trailerAssetId)
        ? techAssets.find((x) => x.id === oper.trailerAssetId).name
        : "";
      let trailerAssetRFID = techAssets.find(
        (x) => x.id === oper.trailerAssetId
      )
        ? techAssets.find((x) => x.id === oper.trailerAssetId).rfid
        : "";
      let techOperationSubGroup = techOperationSubGroups.find(
        (x) => x.id === oper.techOperationSubGroupId
      )
        ? techOperationSubGroups.find(
            (x) => x.id === oper.techOperationSubGroupId
          ).name
        : "";
      let employee = employees.find((x) => x.id === oper.employeeId)
        ? employees.find((x) => x.id === oper.employeeId).fullName
        : "";
      let appUser = appUsers.find((x) => x.id === oper.appUserId)
        ? appUsers.find((x) => x.id === oper.appUserId).fullName
        : "";

      let workStartedAt =
        oper.startedAt === 0 ? "" : getFormateDate(oper.startedAt);
      let workFinishedAt =
        oper.finishedAt === 0 ? "" : getFormateDate(oper.finishedAt);
      let fieldSize = oper.fieldSize;

      let processingDepth = oper.tillage ? oper.tillage.processingDepth : null;
      let quantity, unit, fertiliserName, seedName;

      if (oper.assets.length > 0) {
        quantity = oper.assets[0].quantity;
        // unit = oper.getInfoTechAssets[0].unit;
        unit = "unit";
        fertiliserName = infoData.fertilisers.find(
          (x) => x.id === oper.assets[0].entityType
        )
          ? infoData.fertilisers.find((x) => x.id === oper.assets[0].entityType)
              .name
          : "";
        seedName = infoData.seeds.find(
          (x) => x.id === oper.assets[0].entityType
        )
          ? infoData.seeds.find((x) => x.id === oper.assets[0].entityType).name
          : "";
      }

      let humidity, impurity, harvestedAmount, productivityCommercial;
      if (oper.commercial) {
        humidity = oper.commercial.humidity;
        impurity = oper.commercial.impurity;
        harvestedAmount = oper.commercial.harvestedAmount;
        productivityCommercial = oper.commercial.productivityCommercial;
      }

      let dryPercent,
        grossYieldHumidity,
        dryGrossYield,
        harvestingHumidity,
        dryHarvesting;
      if (oper.feed) {
        dryPercent = oper.feed.dryPercent;
        grossYieldHumidity = oper.feed.grossYieldHumidity;
        dryGrossYield = oper.feed.dryGrossYield;
        harvestingHumidity = oper.feed.harvestingHumidity;
        dryHarvesting = oper.feed.dryHarvesting;
      }

      let excelRow = EXCEL_FIELDS.filter((field) => {
        switch (oper.techOperationGroupId) {
          case "979892A0-D639-11EA-B1CB-3F0E5DDB433F":
            return field.tillage;
          case "97997D00-D639-11EA-B1CB-3F0E5DDB433F":
            return field.fertilizing;
          case "979C1510-D639-11EA-B1CB-3F0E5DDB433F":
            return field.sowing;
          case "979B51C0-D639-11EA-B1CB-3F0E5DDB433F":
            return field.spraying;
          case "979A6760-D639-11EA-B1CB-3F0E5DDB433F":
            switch (oper.productionType) {
              case "feed":
                return field.productionFeed;
              case "commercial":
                return field.productionCommercial;
            }
            break;
        }
      }).map((field) => {
        let excelValue: string | number = "";

        switch (field.part) {
          case "season":
            excelValue = parseInt(season);
            break;
          case "farm":
            excelValue = farm;
            break;
          case "department":
            excelValue = department;
            break;
          case "farmLand":
            excelValue = farmLand;
            break;
          case "area":
            excelValue = area;
            break;
          case "crop":
            excelValue = crop;
            break;
          case "startedAt":
            excelValue = workStartedAt;
            break;
          case "finishedAt":
            excelValue = workFinishedAt;
            break;
          case "techOperationSubGroup":
            excelValue = techOperationSubGroup;
            break;
          case "fieldSize":
            excelValue = fieldSize;
            break;
          case "techAsset":
            excelValue = techAsset;
            break;
          case "techAssetRFID":
            excelValue = techAssetRFID;
            break;
          case "trailerAsset":
            excelValue = trailerAsset;
            break;
          case "trailerAssetRFID":
            excelValue = trailerAssetRFID;
            break;
          case "employee":
            excelValue = employee;
            break;
          case "appUser":
            excelValue = appUser;
            break;
          case "seedName":
            excelValue = seedName;
            break;
          case "fertiliserName":
            excelValue = fertiliserName;
            break;
          case "processingDepth":
            excelValue = processingDepth;
            break;
          case "quantity":
            excelValue = quantity;
            break;
          case "unit":
            excelValue = unit;
            break;
          case "assetRateMin":
            excelValue = "";
            break;

          case "humidity":
            excelValue = humidity;
            break;
          case "impurity":
            excelValue = impurity;
            break;
          case "harvestedAmount":
            excelValue = harvestedAmount;
            break;
          case "productivityCommercial":
            excelValue = productivityCommercial;
            break;

          case "dryPercent":
            excelValue = dryPercent;
            break;
          case "grossYieldHumidity":
            excelValue = grossYieldHumidity;
            break;
          case "dryGrossYield":
            excelValue = dryGrossYield;
            break;
          case "harvestingHumidity":
            excelValue = harvestingHumidity;
            break;
          case "dryHarvesting":
            excelValue = dryHarvesting;
            break;
          default:
            excelValue = "result";
            break;
        }
        return excelValue === null ? { value: "" } : { value: excelValue };
      });

      // return excelRow;

      switch (oper.techOperationGroupId) {
        case "979892A0-D639-11EA-B1CB-3F0E5DDB433F":
          dataExcel.tillage.push(excelRow);
          break;
        case "97997D00-D639-11EA-B1CB-3F0E5DDB433F":
          dataExcel.fertilizing.push(excelRow);
          break;
        case "979C1510-D639-11EA-B1CB-3F0E5DDB433F":
          dataExcel.sowing.push(excelRow);
          break;
        case "979B51C0-D639-11EA-B1CB-3F0E5DDB433F":
          dataExcel.spraying.push(excelRow);
          break;
        case "979A6760-D639-11EA-B1CB-3F0E5DDB433F":
          switch (oper.productionType) {
            case "feed":
              dataExcel.productionFeed.push(excelRow);
              break;
            case "commercial":
              dataExcel.productionCommercial.push(excelRow);
              break;
          }
          break;
      }
    });

    return {
      tillage: [
        {
          columns: headerExcelTillage,
          data: dataExcel.tillage,
        },
      ],
      fertilizing: [
        {
          columns: headerExcelFertilizing,
          data: dataExcel.fertilizing,
        },
      ],
      sowing: [
        {
          columns: headerExcelSowing,
          data: dataExcel.sowing,
        },
      ],
      spraying: [
        {
          columns: headerExcelSpraying,
          data: dataExcel.spraying,
        },
      ],
      productionFeed: [
        {
          columns: headerExcelProductionFeed,
          data: dataExcel.productionFeed,
        },
      ],
      productionCommercial: [
        {
          columns: headerExcelProductionCommercial,
          data: dataExcel.productionCommercial,
        },
      ],
    };
  }
);
