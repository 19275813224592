import { makeStyles, Theme } from "@material-ui/core/styles";
import { SELECTED_BLUE_COLOR } from "src/styles/mui-theme/mui-theme-constants";

export const useObservationPageStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: 8,
  },
  root1: {
    width: "100%",
    height: "auto",
    maxHeight: "860px",
    overflowY: "auto",
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: theme.palette.background.default,
  },
  rowItem: {
    width: "100%",
    marginBottom: 40,
    backgroundColor: theme.palette.background.paper,
  },
  img: {
    width: 100,
  },
  description: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  textAliasesHeader: {
    lineHeight: "24px",
    fontSize: 12,
  },
  textAliases: {
    lineHeight: "14px",
    fontSize: 14,
  },
  editIcon: {
    "&:hover": {
      color: SELECTED_BLUE_COLOR,
    },
  },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2),
      display: "flex",
      justifyContent: "center",
    },
  },
  datapicker: {
    margin: "0 15px",
  },
}));
