export interface IReducersMap {
  [key: string]: (state: any, action: any) => any;
}

export const mergeReducers = (
  state: any,
  action: any,
  reducersMap: IReducersMap
) =>
  Object.keys(reducersMap).reduce((mergedState, reducersStateKey) => {
    const reducer = reducersMap[reducersStateKey];
    const currentReducerState = mergedState
      ? mergedState[reducersStateKey]
      : undefined;
    const newReducersState = reducer(currentReducerState, action);
    if (currentReducerState !== newReducersState) {
      return { ...mergedState, [reducersStateKey]: newReducersState };
    }
    return mergedState;
  }, state);
