import React from "react";
import { EditFormComponent } from "src/components/edit-form";
import { FERTILISER_EDIT_LIST_ITEMS } from "src/config/edit-form";
import { IFertiliserEditFormProps } from "./fertiliser-edit-form-types";

export const FertiliserEditForm = (props: IFertiliserEditFormProps) => {
  const {
    editObjData,
    uniqTypeFertileser,
    addToModifiedParams,
    setModifiedParamsValidity,
    modifiedParams,
    uniqUnit,
    empty,
  } = props;

  const listItemsFunc = {
    unit: uniqUnit,
    type: uniqTypeFertileser,
  };

  return (
    <EditFormComponent
      editName="Удобрения"
      listItemsProp={FERTILISER_EDIT_LIST_ITEMS}
      listItemsFunc={listItemsFunc}
      editObjData={editObjData}
      addToModifiedParams={addToModifiedParams}
      empty={empty}
      setModifiedParamsValidity={setModifiedParamsValidity}
      modifiedParams={modifiedParams}
    />
  );
};
