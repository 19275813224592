import React from "react";
import { Route, Redirect } from "react-router-dom";
import { PATHS } from "src/constant";
import { useAuth } from "./authContext";

export const UnauthenticatedRoute = ({ children, ...rest }: any) => {
  const { isAuthenticated } = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: PATHS.CATALOG_PAGE,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
