import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  fetchCatalogData,
  deleteObjData,
  setEndpoints,
  clearCatalogData,
} from "./catalog-page-actions";
import { CatalogPage } from "./catalog-page";
import { getEndpoints, getSelectedRows } from "./catalog-page-selectors";

const mapStateToProps = createStructuredSelector({
  endpoints: getEndpoints,
  selectedRows: getSelectedRows,
});

const mapDispatchToProps = {
  fetchCatalogData,
  deleteObjData,
  setEndpoints,
  clearCatalogData,
};

export const CatalogPageController = connect(
  mapStateToProps,
  mapDispatchToProps
)(CatalogPage);
