import React from "react";
import { Sidebar } from "src/modules/sidebar";
import { makeStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

export const techOperationsPageStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: 8,
  },
  list: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

const fieldsList = [
  { crop: "Люцерна", field: "47.22.06.045.00", area: 45.3 },
  { crop: "Пшеница", field: "47.22.06.045.01", area: 21.3 },
  { crop: "Подсолнечник", field: "47.22.06.045.02", area: 45.3 },
  { crop: "Подсолнечник", field: "47.22.06.045.03", area: 127 },
  { crop: "Подсолнечник", field: "47.22.06.046.00", area: 63 },
  { crop: "Люцерна", field: "47.22.06.047", area: 67.3 },
  { crop: "Пшеница", field: "47.22.06.047.02", area: 81 },
  { crop: "Пшеница", field: "47.22.06.048.01", area: 81.3 },
  { crop: "Подсолнечник", field: "47.22.06.049.00", area: 85 },
  { crop: "Люцерна", field: "47.22.06.045.00", area: 45.3 },
  { crop: "Пшеница", field: "51.22.06.045.00", area: 67.3 },
  { crop: "Клевер", field: "53.22.06.045.00", area: 54.2 },
  { crop: "Люцерна", field: "23.21.06.045.00", area: 41.3 },
  { crop: "Подсолнечник", field: "47.22.06.045.00", area: 37.2 },
  { crop: "Клевер", field: "47.28.06.045.00", area: 62.3 },
  { crop: "Люцерна", field: "47.19.06.045.00", area: 73.3 },
  { crop: "Подсолнечник", field: "47.19.06.063.00", area: 52.3 },
  { crop: "Подсолнечник", field: "47.19.06.027.00", area: 46.8 },
  { crop: "Люцерна", field: "47.22.06.051.00", area: 42.3 },
  { crop: "Люцерна", field: "47.22.06.045.00", area: 41.1 },
];

export const TechOperationsEditingComponent = (props: any) => {
  const { data } = props;
  const classes = techOperationsPageStyles();

  let [value, setValue] = React.useState<any>();
  let [listFieldsScreen, setListFieldsScreen] = React.useState<any[]>([]);

  if (!data) return null;

  return (
    <div className={classes.root}>
      <Sidebar title="Техоперации" sidebarIndex={0} />

      <main className={classes.content}>
        <div className={classes.toolbar} />

        <Autocomplete
          id="combo-box-demo"
          options={fieldsList}
          getOptionLabel={(option: any) => option.field}
          style={{ width: 300 }}
          onChange={(id, value) => setValue(value)}
          closeIcon={
            <AddCircleOutlineIcon
              fontSize="small"
              onClick={() => {
                console.info(value);
                setListFieldsScreen([...listFieldsScreen, value]);
              }}
            />
          }
          renderOption={(option) => (
            <React.Fragment>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ fontSize: "18px", fontWeight: 800 }}>
                  {option.field}
                </div>
                <div style={{ fontSize: "14px" }}>
                  ({option.crop}, {option.area})
                </div>
              </div>
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField {...params} label="Номер поля" variant="outlined" />
          )}
        />

        <List className={classes.list}>
          <ListItemText primary="Список полей" />
          {listFieldsScreen.map((value) => {
            const labelId = `checkbox-list-label-${value.field}`;

            return (
              <ListItem key={value} role={undefined} dense>
                <ListItemText id={labelId} primary={value.field} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="comments"
                    onClick={(e) => console.info(e)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </main>
    </div>
  );
};
