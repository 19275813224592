import { Dispatch } from "redux";
import { Util } from "src/utils/utils";
import { ThunkDispatch } from "redux-thunk";
import { CatalogDataType, CatalogPageState } from "./catalog-page-types";
import { CATALOG_PAGE_KEY } from "./catalog-page-constants";

const CATALOG_NAMESPACE = "@catalogPage";

export const SET_ENDPOINTS = `${CATALOG_NAMESPACE}/SET_ENDPOINTS`;
export const setEndpoints = (endpoints: string) => ({
  type: SET_ENDPOINTS,
  payload: endpoints,
});

export const SET_CATALOG_DATA = `${CATALOG_NAMESPACE}/SET_CATALOG_DATA`;
export const setCatalogData = (data: CatalogDataType[]) => ({
  type: SET_CATALOG_DATA,
  payload: data,
});

export const SET_FILTER_DATA = `${CATALOG_NAMESPACE}/SET_FILTER_DATA`;
export const setFilterData = (filterData: CatalogDataType[]) => ({
  type: SET_FILTER_DATA,
  payload: filterData,
});

export const fetchCatalogData =
  () =>
  async (
    dispatch: Dispatch,
    getState: () => { [CATALOG_PAGE_KEY]: CatalogPageState }
  ) => {
    const { [CATALOG_PAGE_KEY]: pageState } = getState();
    const fetchData = await Util.getFeaturesAPI(pageState.endpoints);

    dispatch(setCatalogData(fetchData));
  };

export const SET_SELECTED_ROWS = `${CATALOG_NAMESPACE}/SET_SELECTED_ROWS`;
export const setSelectedRows = (rows: string[]) => ({
  type: SET_SELECTED_ROWS,
  payload: rows,
});

export const CLEAR_CATALOG_DATA = `${CATALOG_NAMESPACE}/CLEAR_CATALOG_DATA`;
export const clearCatalogData = () => ({
  type: CLEAR_CATALOG_DATA,
});

export const deleteObjData =
  (idList: string[]) =>
  async (
    dispatch: ThunkDispatch<{}, {}, any>,
    getState: () => { [CATALOG_PAGE_KEY]: CatalogPageState }
  ) => {
    const { [CATALOG_PAGE_KEY]: pageState } = getState();
    idList.forEach(
      async (id) => await Util.deleteFeatureAPI(pageState.endpoints, id)
    );
  };
