import { createStyles, makeStyles } from "@material-ui/core/styles";

export const headerStyles = makeStyles(() =>
  createStyles({
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    headerCell: {
      fontWeight: 600,
      color: "#676767",
      lineHeight: "1.14",
      border: "1px solid #a3a3a3",
      borderRight: 0,
      background: "white",
      padding: "12px 2px 11px 15px",
      whiteSpace: "nowrap",
    },
  })
);
