import { EditFormTypes } from "src/common-types";

export const DISEASES_EDIT_LIST_ITEMS: EditFormTypes[] = [
  {
    id: "name",
    label: "Название болезни: ",
    inputs: [{ type: "string", placeholder: "Название" }],
  },
  {
    id: "latinName",
    label: "Латинское название: ",
    inputs: [{ type: "string", placeholder: "Название" }],
  },
  {
    id: "aliases",
    label: "Альтернативное название: ",
    inputs: [{ type: "multiline", placeholder: "Название" }],
  },
  {
    id: "family",
    label: "Семейство: ",
    inputs: [{ type: "dropdown", placeholder: "Название" }],
  },
];
