import { EditFormTypes } from "src/common-types";

export const WEEDS_EDIT_LIST_ITEMS: EditFormTypes[] = [
  {
    id: "name",
    label: "Название сорняка: ",
    inputs: [{ type: "string", placeholder: "Название" }],
  },
  {
    id: "latinName",
    label: "Латинское название: ",
    inputs: [{ type: "string", placeholder: "Название" }],
  },
  {
    id: "aliases",
    label: "Альтернативное название: ",
    inputs: [{ type: "multiline", placeholder: "Название" }],
  },
  {
    id: "family",
    label: "Семейство: ",
    inputs: [{ type: "dropdown", placeholder: "Название" }],
  },
  {
    id: "genus",
    label: "Род: ",
    inputs: [{ type: "dropdown", placeholder: "Название" }],
  },
  {
    id: "cladeClass",
    label: "Биологический класс: ",
    inputs: [{ type: "dropdown", placeholder: "Название" }],
  },
  {
    id: "cladeGroup",
    label: "Биологическая группа: ",
    inputs: [{ type: "dropdown", placeholder: "Название" }],
  },
  {
    id: "morphology",
    label: "Описание: ",
    inputs: [{ type: "richtext", placeholder: "Название" }],
  },
];
