// import { Dispatch } from "redux";
import { Util } from "src/utils/utils";
import { ThunkDispatch } from "redux-thunk";
import { TECHOPERATIONS_PAGE_KEY } from "./tech-operations-page-constants";
import { getAppUserFarmLands } from "src/modules/info-data";

const TECHOPERATIONS_NAMESPACE = "@techOperationsPage";

export const SET_ENDPOINTS = `${TECHOPERATIONS_NAMESPACE}/SET_ENDPOINTS`;
export const setEndpoints = (endpoints: string) => ({
  type: SET_ENDPOINTS,
  payload: endpoints,
});

export const SET_TECHOPERATIONS_DATA = `${TECHOPERATIONS_NAMESPACE}/SET_TECHOPERATIONS_DATA`;
export const setTechOperationsData = (data: any[]) => ({
  type: SET_TECHOPERATIONS_DATA,
  payload: data,
});

export const SET_FILTER_DATA = `${TECHOPERATIONS_NAMESPACE}/SET_FILTER_DATA`;
export const setFilterData = (filterData: any[]) => ({
  type: SET_FILTER_DATA,
  payload: filterData,
});

export const fetchTechOperationsData =
  () => async (dispatch: ThunkDispatch<{}, {}, any>, getState) => {
    const { [TECHOPERATIONS_PAGE_KEY]: pageState } = getState();
    const listAppUserFarmLands = getAppUserFarmLands(getState());
    // if (!listAppUserFarmLands) return;
    if (!pageState) return;

    const fetchData = await Util.getFeaturesAPI(
      "TechOperations",
      undefined,
      undefined,
      500,
      pageState.selectedPeriod,
      undefined,
      undefined,
      undefined,
      pageState.curSeasonId
    );

    dispatch(setTechOperationsData(fetchData));
  };

export const SET_EXCEL_DATA = `${TECHOPERATIONS_NAMESPACE}/SET_EXCEL_DATA`;
export const setExcelData = (filterData: any[]) => ({
  type: SET_EXCEL_DATA,
  payload: filterData,
});

export const fetchTechOperationsDataAsset =
  () => async (dispatch: ThunkDispatch<{}, {}, any>, getState) => {
    console.info("fetchTechOperationsDataAsset");
    // getAppUserFarmLands(getState)

    const { [TECHOPERATIONS_PAGE_KEY]: pageState } = getState();

    if (!pageState.data) return;

    dispatch(setExcelData(pageState.data));
  };

export const SET_SELECTED_ROWS = `${TECHOPERATIONS_NAMESPACE}/SET_SELECTED_ROWS`;
export const setSelectedRows = (rows: string[]) => ({
  type: SET_SELECTED_ROWS,
  payload: rows,
});

export const SET_SELECTED_PERIOD = `${TECHOPERATIONS_NAMESPACE}/SET_SELECTED_PERIOD`;
export const setSelectedPeriod = (period: {
  dateFrom: Date;
  dateTo: Date;
}) => ({
  type: SET_SELECTED_PERIOD,
  payload: period,
});

export const SET_CURRENT_SEASON = `${TECHOPERATIONS_NAMESPACE}/SET_CURRENT_SEASON`;
export const setCurrentSeason = (season) => ({
  type: SET_CURRENT_SEASON,
  payload: season,
});

export const deleteObjData =
  (idList: string[]) =>
  async (
    dispatch: ThunkDispatch<{}, {}, any>,
    getState: () => { [TECHOPERATIONS_PAGE_KEY]: any }
  ) => {
    const { [TECHOPERATIONS_PAGE_KEY]: pageState } = getState();
    idList.forEach(
      async (id) => await Util.deleteFeatureAPI(pageState.endpoints, id)
    );

    dispatch(fetchTechOperationsData());
  };

export const SET_MODIFY = `${TECHOPERATIONS_NAMESPACE}/SET_MODIFY`;
export const setModify = (param: "add" | "edit") => ({
  type: SET_MODIFY,
  payload: param,
});
