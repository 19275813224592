import React from "react";
import { EditFormComponent } from "src/components/edit-form";
import { PHENOPHASE_EDIT_LIST_ITEMS } from "src/config/edit-form";
import { ISeedEditFormProps } from "./phenophase-edit-form-types";

export const PhenophaseEditForm = (props: ISeedEditFormProps) => {
  const { editObjData, addToModifiedParams, empty } = props;
  // if (!editObjData) return null;

  return (
    <EditFormComponent
      editName="Фенофазы"
      listItemsProp={PHENOPHASE_EDIT_LIST_ITEMS}
      editObjData={editObjData}
      addToModifiedParams={addToModifiedParams}
      empty={empty}
    />
  );
};
