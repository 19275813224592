import { useContext, createContext } from "react";
import { AuthContextTypes } from "src/common-types";

export const authContext = createContext<AuthContextTypes>({
  login: async () => false,
  logout: () => {},
  isAuthenticated: null,
  userHasAuthenticated: () => null,
});

export const useAuth = () => {
  return useContext(authContext);
};
