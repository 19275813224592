import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { ExportDataToExcel } from "./export-data";
import { createGetExcelData, getSelectedPeriod } from "./export-data-selectors";
import {
  setCurrentSeason,
  setSelectedPeriod,
  // fetchTechOperationsDataAsset,
} from "../tech-operations-page-actions";
import { getInfoSeasons } from "src/modules/info-data";
import { getCurrentSeason } from "../tech-operations-page-selectors";

const mapStateToProps = createStructuredSelector({
  excelData: createGetExcelData,
  selectedPeriod: getSelectedPeriod,
  seasons: getInfoSeasons,
  curSeason: getCurrentSeason,
});

const mapDispatchToProps = {
  setSelectedPeriod,
  setCurrentSeason,
  // fetchTechOperationsDataAsset,
};

export const ExportDataToExcelController = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExportDataToExcel);
