import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getEndpoints } from "../../catalog-page-selectors";
import {
  patchEditObjData,
  postObjData,
  clearEditObjData,
} from "../catalog-editing-page-actions";
import {
  getIsFormModified,
  getModifiedParams,
  getModifiedParamsValidity,
  getModify,
} from "../catalog-editing-page-selectors";
import { CatalogEditingTabpanelComponent } from "./catalog-editing-tabpanel";

const mapStateToProps = createStructuredSelector({
  modify: getModify,
  modifiedParams: getModifiedParams,
  endpoints: getEndpoints,
  areValidModifiedParams: getModifiedParamsValidity,
  isFormModified: getIsFormModified,
});

const mapDispatchToProps = {
  patchEditObjData,
  postObjData,
  clearEditObjData,
};

export const CatalogEditingTabpanelController = connect(
  mapStateToProps,
  mapDispatchToProps
)(CatalogEditingTabpanelComponent);
