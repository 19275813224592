import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { PATHS } from "./constant";
import { CatalogPage } from "./pages/catalog-page";
import { CatalogEditingPage } from "./pages/catalog-page/catalog-editing-page";
// import {
//   TechOperationsEditingPage,
//   TechOperationsPage,
// } from "./pages/tech-operations-page";
// import { ObservationsPage } from "./pages/observation-page";
import { Login } from "./pages/login";
import {
  AuthenticatedRoute,
  ProvideAuth,
  UnauthenticatedRoute,
} from "./authentication";

export const AppRouter = () => {
  return (
    <ProvideAuth>
      <Router>
        <Switch>
          <UnauthenticatedRoute exact path={PATHS.LOGIN_PAGE}>
            <Login />
          </UnauthenticatedRoute>
          <AuthenticatedRoute exact path={PATHS.MAIN_PAGE}></AuthenticatedRoute>
          <AuthenticatedRoute exact path={PATHS.CATALOG_PAGE}>
            <CatalogPage />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path={PATHS.CATALOG_PAGE + PATHS.EDIT_PAGE}>
            <CatalogEditingPage />
          </AuthenticatedRoute>
          {/* <AuthenticatedRoute exact path={PATHS.TECHOPERATIONS_PAGE}>
            <TechOperationsPage />
          </AuthenticatedRoute>
          <AuthenticatedRoute
            exact
            path={PATHS.TECHOPERATIONS_PAGE + PATHS.EDIT_PAGE}
          >
            <TechOperationsEditingPage />
          </AuthenticatedRoute> */}
          {/* <AuthenticatedRoute exact path={PATHS.OBSERVATIONS_PAGE}>
            <ObservationsPage />
          </AuthenticatedRoute> */}
        </Switch>
      </Router>
    </ProvideAuth>
  );
};
