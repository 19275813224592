import { API_URL } from "src/config/api";

/**
 * FIND all instances of the model / a model instance by {{id}} matched by filter from the data source.
 * @param {string} endpoints endpoints request api
 * @param {string} id Optional. Model id
 * @param {string} part Optional.
 * @param {number} limit Optional.
 * @param {{ dateFrom: Date; dateTo: Date }} filter Optional.
 * @param {string[]} inq Optional.
 */

const getFeaturesAPI = async (
  endpoints: string,
  id?: string,
  part?: string,
  limit?: number,
  filter?: { dateFrom: Date; dateTo: Date },
  fields?: { [key: string]: boolean },
  inq?: string[],
  techOp?: boolean,
  season?: string,
  observation?: boolean
) => {
  const ACCESS_TOKEN = localStorage["access_token"];

  const url = part
    ? new URL(
        `${API_URL}/${endpoints}/${id}/${part}?access_token=${ACCESS_TOKEN}`
      )
    : id
    ? new URL(`${API_URL}/${endpoints}/${id}?access_token=${ACCESS_TOKEN}`)
    : observation && filter
    ? new URL(
        // `${API_URL}/${endpoints}?access_token=${ACCESS_TOKEN}&filter[limit]=500&filter[include]=crop&filter[include]=appUser&filter[include]=farm&filter[include]=farmLand&filter[include]=phenoPhase&`
        // `${API_URL}/${endpoints}?access_token=${ACCESS_TOKEN}&filter[limit]=500&filter[include]=crop&filter[include]=appUser&filter[include]=farm&filter[include]=farmLand&filter[include]=phenoPhase&&filter[where][seasonId]=${season}`
        `${API_URL}/${endpoints}?access_token=${ACCESS_TOKEN}&filter[limit]=500&filter[include]=crop&filter[include]=appUser&filter[include]=farm&filter[include]=farmLand&filter[include]=phenoPhase&filter[where][and][0][inputDate][gt]=${filter.dateFrom}&filter[where][and][1][inputDate][lt]=${filter.dateTo}&filter[where][and][2][seasonId]=${season}`
        // `${API_URL}/${endpoints}?access_token=${ACCESS_TOKEN}&filter[limit]=500&filter[include]=crop`
      )
    : filter
    ? new URL(
        `${API_URL}/${endpoints}?access_token=${ACCESS_TOKEN}&filter[include]=assets&filter[where][and][0][startedAt][gt]=${filter.dateFrom}&filter[where][and][1][startedAt][lt]=${filter.dateTo}&filter[where][and][2][seasonId]=${season}&filter[limit]=25000&filter={"where": {and: [{"farmId": {"inq": [${inq}]}}]}}`
      )
    : fields
    ? new URL(
        `${API_URL}/${endpoints}?access_token=${ACCESS_TOKEN}&filter={ "fields": ${JSON.stringify(
          fields
        )} }`
      )
    : inq
    ? new URL(
        `${API_URL}/${endpoints}?access_token=${ACCESS_TOKEN}&?filter={"where": {"id": {"inq": ${inq}}}}`
      )
    : limit
    ? new URL(
        `${API_URL}/${endpoints}?access_token=${ACCESS_TOKEN}&filter[limit]=${limit}`
      )
    : techOp
    ? new URL(
        `${API_URL}/${endpoints}?access_token=${ACCESS_TOKEN}&filter[include]=assets`
      )
    : new URL(`${API_URL}/${endpoints}?access_token=${ACCESS_TOKEN}`);
  // debugger;
  try {
    if (inq) console.info(url.toString());
    const response = await fetch(url.toString(), {});
    const jsonResponse = await response.json();
    return jsonResponse;
  } catch {
    console.log(`Ошибка загрузки данных.`);
    return;
  }
};

/**
 * PATCH attributes for a model instance and persist it into the data source.
 * @param {string} endpoints endpoints request api
 * @param {string} id model id
 * @param {object} data model instance data
 */

const patchFeatureAPI = async (endpoints: string, id: string, data: object) => {
  const ACCESS_TOKEN = localStorage["access_token"];

  const url = new URL(
    `${API_URL}/${endpoints}/${id}?access_token=${ACCESS_TOKEN}`
  );

  try {
    let response = await fetch(url.toString(), {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });

    const jsonResponse = await response.json();
    return jsonResponse;
  } catch {
    console.log(`Ошибка редактирования данных.`);
    return;
  }
};

/**
 * CREATE a new instance of the model and persist it into the data source.
 * @param {string} endpoints endpoints request api
 * @param {object} data model instance data
 */

const postFeatureAPI = async (endpoints: string, data: object) => {
  const ACCESS_TOKEN = localStorage["access_token"];

  const url = new URL(`${API_URL}/${endpoints}?access_token=${ACCESS_TOKEN}`);

  try {
    let response = await fetch(url.toString(), {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });

    const jsonResponse = await response.json();
    return jsonResponse;
  } catch {
    console.log(`Ошибка создания объекта.`);
    return;
  }
};

/**
 * DELETE a model instance by {{id}} from the data source.
 * @param {string} endpoints endpoints request api
 * @param {string} id model id
 */

const deleteFeatureAPI = async (endpoints: string, id: string) => {
  const ACCESS_TOKEN = localStorage["access_token"];

  const url = new URL(
    `${API_URL}/${endpoints}/${id}?access_token=${ACCESS_TOKEN}`
  );

  try {
    let response = await fetch(url.toString(), {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    });

    const jsonResponse = await response.json();
    return jsonResponse.count === 0 ? false : true;
  } catch {
    console.log(`Объект не удалось удалить.`);
    return;
  }
};

export class Util {
  static getFeaturesAPI = getFeaturesAPI;
  static patchFeatureAPI = patchFeatureAPI;
  static postFeatureAPI = postFeatureAPI;
  static deleteFeatureAPI = deleteFeatureAPI;
}
