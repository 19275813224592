import { EditFormTypes } from "src/common-types";
import { NAMING_VALIDATION_PATTERN } from "./validation-constants";

export const PERSONALS_EDIT_LIST_ITEMS: EditFormTypes[] = [
  {
    id: "lastName",
    label: "Фамилия: ",
    inputs: [{ type: "string", placeholder: "Фамилия" }],
    isRequired: true,
    validationPatterns: NAMING_VALIDATION_PATTERN,
  },
  {
    id: "firstName",
    label: "Имя: ",
    inputs: [{ type: "string", placeholder: "Имя" }],
    isRequired: true,
    validationPatterns: NAMING_VALIDATION_PATTERN,
  },
  {
    id: "middleName",
    label: "Отчество: ",
    inputs: [{ type: "string", placeholder: "Отчество" }],
    validationPatterns: NAMING_VALIDATION_PATTERN,
  },
  {
    id: "positionName",
    label: "Должность: ",
    inputs: [{ type: "dropdown", placeholder: "Должность" }],
    isRequired: true,
  },
  {
    id: "importFarmName",
    label: "Доступные хозяйства: ",
    inputs: [{ type: "multiselect", placeholder: "Доступные хозяйства" }],
    isRequired: true,
  },
  {
    id: "workPlaceName",
    label: "Место работы: ",
    inputs: [{ type: "dropdown", placeholder: "Место работы" }],
    isRequired: true,
  },
  {
    id: "fullName",
    label: "Полное имя",
    inputs: [{ type: "string", placeholder: "Полное имя" }],
    isReadonly: true,
    compositionTemplate: {
      lastName: "",
      firstName: " ",
      middleName: " ",
    },
  },
];
