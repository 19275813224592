import { EditFormTypes } from "src/common-types";

export const SZR_EDIT_LIST_ITEMS: EditFormTypes[] = [
  {
    id: "name",
    label: "Название СЗР: ",
    inputs: [{ type: "string", placeholder: "Название СЗР" }],
    isRequired: true,
  },
  {
    id: "type",
    label: "Тип СЗР: ",
    inputs: [{ type: "dropdown", placeholder: "Название СЗР" }],
    isRequired: true,
  },
  {
    id: "unit",
    label: "Единица измерения: ",
    inputs: [{ type: "dropdown", placeholder: "Название СЗР" }],
    isRequired: true,
  },
];
