import React from "react";
import { EditFormComponent } from "src/components/edit-form";
import { SEEDS_EDIT_LIST_ITEMS } from "src/config/edit-form";
import { ISeedEditFormProps } from "./seed-edit-form-types";

export const SeedEditForm = (props: ISeedEditFormProps) => {
  const {
    editObjData,
    uniqCropType,
    uniqVariety,
    addToModifiedParams,
    setModifiedParamsValidity,
    modifiedParams,
    empty,
  } = props;

  const listItemsFunc = {
    cropName: uniqCropType,
    varietyName: uniqVariety,
  };
  // if (!editObjData) return null;

  return (
    <EditFormComponent
      editName="СЗР"
      listItemsProp={SEEDS_EDIT_LIST_ITEMS}
      listItemsFunc={listItemsFunc}
      editObjData={editObjData}
      addToModifiedParams={addToModifiedParams}
      empty={empty}
      setModifiedParamsValidity={setModifiedParamsValidity}
      modifiedParams={modifiedParams}
    />
  );
};
