import { makeStyles } from "@material-ui/core";

export const catalogTabpanelStyles = makeStyles((theme) => ({
  root: { background: theme.palette.background.paper },
  inputSearch: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
    display: "flex",
    justifyContent: "space-between",
  },
  manualHeader: {
    height: 50,
    color: "#676767",
    fontSize: 20,
    padding: "10px 8px",
    borderBottom: "1px solid #d9d9d9",
  },
  manualBody: {},
  manualFooter: {
    bottom: 0,
    height: 70,
    fontWeight: 600,
    padding: "16px 8px",
    borderTop: "1px solid #d9d9d9",
  },
}));
