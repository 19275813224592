import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
  Avatar,
  Button,
  FormControl,
  Paper,
  Typography,
} from "@material-ui/core";
import { useAuth } from "src/authentication";
import { LoginFormDataTypes } from "src/common-types";
import { InputLoginFormTypes } from "./login-page-types";
import { InputEmail } from "./input-email";
import { InputPassword } from "./input-password";
import AvatarImg from "./img/img_crop.jpg";
import "./login-page.scss";
import { AlertComponent } from "src/components/alerts";

export const Login = () => {
  let auth = useAuth();
  let history = useHistory();

  const { getValues, handleSubmit, register } = useForm<LoginFormDataTypes>();

  const [params, setParams] = React.useState<InputLoginFormTypes>({
    email: "",
    password: "",
    showPassword: false,
  });

  const [error, showError] = React.useState<boolean>(false);

  function validateForm() {
    return (
      getValues("email") &&
      getValues("password") &&
      getValues("email").length > 0 &&
      getValues("password").length > 0
    );
  }

  const onSubmit = handleSubmit((data) => {
    auth.login(data).then((result: boolean) => {
      if (!result) showError(true);
    });
  });

  return (
    <div className="login-page">
      <form onSubmit={onSubmit}>
        <Paper className="login-component">
          <Avatar src={AvatarImg} className="avatar" />

          <Typography variant="h5" gutterBottom>
            EkoCrop
          </Typography>
          <Typography variant="body2" gutterBottom>
            Введите логин и пароль выданные Вам администратором
          </Typography>
          <div className="login-form">
            {error && (
              <AlertComponent
                type="error"
                text="Неверный логин или пароль. Повторите попытку ещё раз."
              />
            )}

            <InputEmail
              inputRef={register}
              values={params}
              setValues={setParams}
            />
            <InputPassword
              inputRef={register}
              values={params}
              setValues={setParams}
            />

            <FormControl>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!validateForm()}
                className="btn-login"
              >
                Войти
              </Button>
            </FormControl>
          </div>
        </Paper>
      </form>
    </div>
  );
};
