import React from "react";
import { Box, Tab, Tabs } from "@material-ui/core";
import {
  listTabsItemsProps,
  TabPanelProps,
  VerticalTabsProps,
} from "./vertical-tabs-types";
import { VerticalTabsStyles } from "./vertical-tabs-styles";

function TabPanel(props: TabPanelProps) {
  const { children, value, index, className, ...other } = props;

  return (
    <div
      className={className}
      role="tabpanel"
      hidden={value !== index}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export const VerticalTabsComponent = (props: VerticalTabsProps) => {
  const { defaultValueTab, listTabsItems, onChangeTab } = props;
  const classes = VerticalTabsStyles();
  const [value, setValue] = React.useState(defaultValueTab);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) =>
    onChangeTab(newValue)?.then(() => setValue(newValue));

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        {listTabsItems.map((item: listTabsItemsProps, index: number) => (
          <Tab key={"tab_" + index} label={item.label} value={item.stateName} />
        ))}
      </Tabs>
      {listTabsItems.map((item: listTabsItemsProps, index: number) => (
        <TabPanel
          key={"tabpanel_" + index}
          value={value}
          index={item.stateName}
          className={classes.tabpanel}
        >
          {item.component}
        </TabPanel>
      ))}
    </div>
  );
};
