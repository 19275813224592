import { createSelector } from "reselect";
import { getCatalogData } from "src/pages/catalog-page/catalog-page-selectors";

export const getUniqFamily = createSelector(getCatalogData, (data: any) =>
  data
    .map((item: { family: string }) => item.family)
    .filter(
      (value: any, index: any, self: string | any[]) =>
        self.indexOf(value) === index
    )
    .sort()
);

export const getUniqGenus = createSelector(getCatalogData, (data: any) =>
  data
    .map((item: { genus: string }) => item.genus)
    .filter(
      (value: any, index: any, self: string | any[]) =>
        self.indexOf(value) === index
    )
    .sort()
);

export const getUniqBioClass = createSelector(getCatalogData, (data: any) =>
  data
    .map((item: { cladeClass: string }) => item.cladeClass)
    .filter(
      (value: any, index: any, self: string | any[]) =>
        self.indexOf(value) === index
    )
    .sort()
);

export const getUniqBioGroup = createSelector(getCatalogData, (data: any) =>
  data
    .map((item: { cladeGroup: string }) => item.cladeGroup)
    .filter(
      (value: any, index: any, self: string | any[]) =>
        self.indexOf(value) === index
    )
    .sort()
);
