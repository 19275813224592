import { debug } from "console";
import { Dispatch } from "redux";
import { ModifiedParamsTypes } from "src/common-types";
import { INFO_DATA_KEY } from "src/modules/info-data/info-data-constants";
import { Util } from "src/utils/utils";
import { CATALOG_PAGE_KEY } from "..";
import { CATALOG_SECTIONS_ENUM } from "../catalog-page-constants";
import { CatalogDataType } from "../catalog-page-types";
import { CATALOG_EDITING_PAGE_KEY } from "./catalog-editing-page-constants";
import { CatalogPageStateWithEditing } from "./catalog-editing-page-types";

const CATALOG_EDIT_NAMESPACE = "@catalogEdit";

export const SET_EDIT_OBJ_ID = `${CATALOG_EDIT_NAMESPACE}/SET_EDIT_OBJ_ID`;
export const setEditObjId = (id: string) => ({
  type: SET_EDIT_OBJ_ID,
  payload: id,
});

export const SET_EDIT_OBJ_DATA = `${CATALOG_EDIT_NAMESPACE}/SET_EDIT_OBJ_DATA`;
export const setEditObjData = (data: CatalogDataType) => ({
  type: SET_EDIT_OBJ_DATA,
  payload: data,
});

export const SET_MODIFY = `${CATALOG_EDIT_NAMESPACE}/SET_MODIFY`;
export const setModify = (param: "add" | "edit") => ({
  type: SET_MODIFY,
  payload: param,
});

export const SET_MODIFIED_PARAMS_VALIDITY = `${CATALOG_EDIT_NAMESPACE}/SET_MODIFIED_PARAMS_VALIDITY`;
export const setModifiedParamsValidity = (param: boolean) => ({
  type: SET_MODIFIED_PARAMS_VALIDITY,
  payload: param,
});

export const ADD_TO_MODIFIED_PARAMS = `${CATALOG_EDIT_NAMESPACE}/ADD_TO_MODIFIED_PARAMS`;
export const addToModifiedParams =
  (params: ModifiedParamsTypes) =>
  async (
    dispatch: Dispatch,
    getState: () => { [CATALOG_PAGE_KEY]: CatalogPageStateWithEditing }
  ) => {
    const {
      [CATALOG_PAGE_KEY]: { [CATALOG_EDITING_PAGE_KEY]: pageEditingState },
    } = getState();

    const paramsToChange = { ...pageEditingState.modifiedParams, ...params };

    dispatch({
      type: ADD_TO_MODIFIED_PARAMS,
      payload: paramsToChange,
    });
  };

export const CLEAR_EDIT_OBJ_DATA = `${CATALOG_EDIT_NAMESPACE}/CLEAR_EDIT_OBJ_DATA`;
export const clearEditObjData = () => ({
  type: CLEAR_EDIT_OBJ_DATA,
});

export const fetchEditObjData =
  () =>
  async (
    dispatch: Dispatch,
    getState: () => {
      [CATALOG_PAGE_KEY]: CatalogPageStateWithEditing;
      [INFO_DATA_KEY];
    }
  ) => {
    const {
      [CATALOG_PAGE_KEY]: {
        endpoints,
        [CATALOG_EDITING_PAGE_KEY]: pageEditingState,
      },
      [INFO_DATA_KEY]: info_data,
    } = getState();

    if (!pageEditingState.objId) return null;

    const fetchData = await Util.getFeaturesAPI(
      endpoints,
      pageEditingState.objId
    );

    switch (endpoints) {
      case CATALOG_SECTIONS_ENUM.APPUSERS:
        if (fetchData["defaultFarmId"]) {
          let defaultFarm = info_data.farms.find(
            (farm) => farm.id === fetchData["defaultFarmId"]
          );
          fetchData["defaultFarmName"] = defaultFarm ? defaultFarm.name : null;
        }
        break;

      case CATALOG_SECTIONS_ENUM.PERSONALS:
        if (fetchData["workPlaceId"]) {
          let workPlace = info_data.workPlace.find(
            (workPlaceItem) => workPlaceItem.id === fetchData["workPlaceId"]
          );
          fetchData["workPlaceName"] = workPlace ? workPlace.name : null;
        }

        break;
    }

    dispatch(setEditObjData(fetchData));
  };

export const patchEditObjData =
  (data: CatalogDataType) =>
  async (
    dispatch: Dispatch,
    getState: () => {
      [CATALOG_PAGE_KEY]: CatalogPageStateWithEditing;
      [INFO_DATA_KEY];
    }
  ) => {
    const {
      [CATALOG_PAGE_KEY]: {
        endpoints,
        [CATALOG_EDITING_PAGE_KEY]: pageEditingState,
      },
      [INFO_DATA_KEY]: info_data,
    } = getState();

    if (!pageEditingState.objId) return null;

    switch (endpoints) {
      case CATALOG_SECTIONS_ENUM.TECHNICKS:
        if (data["techTypeName"]) {
          let infoTechTypesItem = info_data.techTypes.find(
            (item) => item.name === data["techTypeName"]
          );
          data["techTypeId"] = infoTechTypesItem ? infoTechTypesItem.id : "";
        }
        if (data["importTechCategory"]) {
          data["category"] =
            data["importTechCategory"] === "Самоходная" ? "tech" : "trailer";
        }

        break;

      case CATALOG_SECTIONS_ENUM.APPUSERS:
        if (data["defaultFarmName"]) {
          let defaultFarm = info_data.farms.find(
            (farm) => farm.name === data["defaultFarmName"]
          );
          if (defaultFarm) {
            data["defaultFarmId"] = defaultFarm["id"];
            data["importHoldingId"] = defaultFarm["importHoldingId"];
          }
        }

        data["importId"] = data["email"];
        break;

      case CATALOG_SECTIONS_ENUM.PERSONALS:
        if (data["positionName"]) {
          let infoPositionsItem = info_data.positions.find(
            (position) => position.name === data["positionName"]
          );
          data["positionId"] = infoPositionsItem ? infoPositionsItem.id : "";
        }

        if (data["workPlaceName"]) {
          let workPlace = info_data.workPlace.find(
            (workPlaceItem) => workPlaceItem.name === data["workPlaceName"]
          );
          if (workPlace) {
            data["workPlaceId"] = workPlace["id"];
          }
        }

        break;
    }

    await Util.patchFeatureAPI(endpoints, pageEditingState.objId, data);
  };

export const postObjData =
  (data: CatalogDataType) =>
  async (
    dispatch: Dispatch,
    getState: () => {
      [CATALOG_PAGE_KEY]: CatalogPageStateWithEditing;
      [INFO_DATA_KEY];
    }
  ) => {
    const {
      [CATALOG_PAGE_KEY]: { endpoints },
      [INFO_DATA_KEY]: info_data,
    } = getState();

    switch (endpoints) {
      case CATALOG_SECTIONS_ENUM.TECHNICKS:
        let infoTechTypesItem = info_data.techTypes.find(
          (item) => item.name === data["techTypeName"]
        );
        data["techTypeId"] = infoTechTypesItem ? infoTechTypesItem.id : "";

        data["category"] =
          data["importTechCategory"] === "Самоходная" ? "tech" : "trailer";

        postObjData(data);
        break;

      case CATALOG_SECTIONS_ENUM.PERSONALS:
        let infoPositionsItem = info_data.positions.find(
          (item) => item.name === data["positionName"]
        );
        data["positionId"] = infoPositionsItem ? infoPositionsItem.id : "";

        let workPlace = info_data.workPlace.find(
          (workPlaceItem) => workPlaceItem.name === data["workPlaceName"]
        );
        if (workPlace) {
          data["workPlaceId"] = workPlace["id"];
        }

        postObjData(data);
        break;

      case CATALOG_SECTIONS_ENUM.APPUSERS:
        let defaultFarm = info_data.farms.find(
          (farm) => farm.name === data["defaultFarmName"]
        );
        if (defaultFarm) {
          data["defaultFarmId"] = defaultFarm["id"];
          data["importHoldingId"] = defaultFarm["importHoldingId"];
        }

        data["importId"] = data["email"];
        data["roleName"] = "user";
        break;

      case CATALOG_SECTIONS_ENUM.SZR:
        postObjData(data);
        break;

      case CATALOG_SECTIONS_ENUM.FERTILISER:
        postObjData(data);
        break;

      case CATALOG_SECTIONS_ENUM.SEEDS:
        let infoCropTypeItem = info_data.cropTypes.find(
          (item) => item.name === data["cropName"]
        );
        data["cropTypeId"] = infoCropTypeItem ? infoCropTypeItem.id : "";
        postObjData(data);
        break;
    }

    await Util.postFeatureAPI(endpoints, data);
  };
