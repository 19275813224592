import { makeStyles, Theme } from "@material-ui/core";

export const VerticalTabsStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: 0,
  },
  tabs: {
    display: "inline-table",
    width: 200,
    height: "auto",
  },
  tabpanel: {
    width: "100%",
  },
}));
