import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  addToModifiedParams,
  setModifiedParamsValidity,
} from "../../catalog-editing-page-actions";
import {
  getEditObjData,
  getModifiedParams,
} from "../../catalog-editing-page-selectors";
import { TechnicEditForm } from "./technic-edit-form";
import {
  getUniqFarm,
  getUniqOwner,
  getUniqTechBrand,
  getUniqTechModel,
  getUniqtechOperationGroupName,
  getUniqtechOperationSubGroupName,
  getUniqTypeName,
  getCategory,
} from "./technic-edit-form-selectors";

const mapStateToProps = createStructuredSelector({
  editObjData: getEditObjData,
  modifiedParams: getModifiedParams,
  uniqTypes: getUniqTypeName,
  uniqBrands: getUniqTechBrand,
  uniqModels: getUniqTechModel,
  uniqFarm: getUniqFarm,
  uniqOwner: getUniqOwner,
  uniqtechOperationGroupName: getUniqtechOperationGroupName,
  uniqtechOperationSubGroupName: getUniqtechOperationSubGroupName,
  techAssetCategory: getCategory,
});

const mapDispatchToProps = {
  addToModifiedParams,
  setModifiedParamsValidity,
};

export const TechnicEditFormController = connect(
  mapStateToProps,
  mapDispatchToProps
)(TechnicEditForm);
