import { CatalogDataType } from "./catalog-page-types";

export const filterByValue = (array: CatalogDataType[], string: string) => {
  return array.filter((o) =>
    Object.keys(o).some((k) => {
      return o[k] && typeof o[k] === "string"
        ? (o[k] as string).toLowerCase().includes(string.toLowerCase())
        : null;
    })
  );
};
