import {
  APPUSERS_COLUMNS,
  FERTILISER_COLUMNS,
  PERSONALS_COLUMNS,
  SEED_COLUMNS,
  SZR_COLUMNS,
  TECHNICS_COLUMNS,
} from "src/config/tables";
import { CatalogPageState, CatalogSectionInfo } from "./catalog-page-types";

export const CATALOG_PAGE_KEY = "catalog_page";

export enum CATALOG_SECTIONS_ENUM {
  TECHNICKS = "TechAssets", // данные по технике
  PERSONALS = "Employees", //"AppUsers", // данные по персоналу
  APPUSERS = "AppUsers", //"AppUsers", // данные по персоналу
  SZR = "Chemicals", // данные по СЗР
  FERTILISER = "Fertilisers", // данные по удобрениям
  SEEDS = "Seeds", // данные по семенам
  PHENOPHASE = "PhenoPhases", // данные по сорнякам
  WEEDS = "Weeds", // данные по сорнякам
  PESTS = "Pests", // данные по вредителям
  DISEASES = "Diseases", // данные по болезням
}

export const CATALOG_SECTIONS_INFO: CatalogSectionInfo = {
  [CATALOG_SECTIONS_ENUM.TECHNICKS]: {
    name: "Техника",
    btnAddName: "Добавить технику",
    columns: TECHNICS_COLUMNS,
  },
  [CATALOG_SECTIONS_ENUM.PERSONALS]: {
    name: "Персонал",
    btnAddName: "Добавить сотрудника",
    columns: PERSONALS_COLUMNS,
  },
  [CATALOG_SECTIONS_ENUM.APPUSERS]: {
    name: "Пользователи",
    btnAddName: "Добавить пользователя",
    columns: APPUSERS_COLUMNS,
  },
  [CATALOG_SECTIONS_ENUM.SZR]: {
    name: "СЗР",
    btnAddName: "Добавить СЗР",
    columns: SZR_COLUMNS,
  },
  [CATALOG_SECTIONS_ENUM.FERTILISER]: {
    name: "Удобрения",
    btnAddName: "Добавить удобрения",
    columns: FERTILISER_COLUMNS,
  },
  [CATALOG_SECTIONS_ENUM.SEEDS]: {
    name: "Семена",
    btnAddName: "Добавить семена",
    columns: SEED_COLUMNS,
  },
  [CATALOG_SECTIONS_ENUM.PHENOPHASE]: {
    name: "Фенофазы",
    btnAddName: "Добавить фенофазу",
    columns: SEED_COLUMNS, // TODO change
  },
  [CATALOG_SECTIONS_ENUM.WEEDS]: {
    name: "Сорняки",
    btnAddName: "Добавить сорняк",
    columns: SEED_COLUMNS, // TODO change
  },
  [CATALOG_SECTIONS_ENUM.PESTS]: {
    name: "Вредители",
    btnAddName: "Добавить вредителя",
    columns: SEED_COLUMNS, // TODO change
  },
  [CATALOG_SECTIONS_ENUM.DISEASES]: {
    name: "Болезни",
    btnAddName: "Добавить болезнь",
    columns: SEED_COLUMNS, // TODO change
  },
};

export const CATALOG_INITIAL_STATE: CatalogPageState = {
  endpoints: CATALOG_SECTIONS_ENUM.PERSONALS,
  selectedRowsId: [],
};
