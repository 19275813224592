import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { setFilterData, setSelectedRows } from "../catalog-page-actions";
import { setModify } from "../catalog-editing-page/catalog-editing-page-actions";
import {
  getCatalogData,
  getEndpoints,
  getSelectedRows,
} from "../catalog-page-selectors";
import { CatalogTabpanelComponent } from "./catalog-tabpanel";

const mapStateToProps = createStructuredSelector({
  endpoints: getEndpoints,
  data: getCatalogData,
  selectedRows: getSelectedRows,
});

const mapDispatchToProps = {
  setSelectedRows,
  setFilterData,
  setModify,
};

export const CatalogTabpanelController = connect(
  mapStateToProps,
  mapDispatchToProps
)(CatalogTabpanelComponent);
