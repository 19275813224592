import { EditFormTypes } from "src/common-types";

export const SEEDS_EDIT_LIST_ITEMS: EditFormTypes[] = [
  {
    id: "name",
    label: "Полное название семян: ",
    inputs: [{ type: "string", placeholder: "Название" }],
    isRequired: true,
  },
  {
    id: "cropName",
    label: "Культура: ",
    inputs: [{ type: "dropdown", placeholder: "Название" }],
    isRequired: true,
  },
  {
    id: "sortName",
    label: "Сорт/ Гибрид: ",
    inputs: [{ type: "string", placeholder: "Название" }],
    isRequired: true,
  },
  {
    id: "varietyName",
    label: "Репродукция: ",
    inputs: [{ type: "dropdown", placeholder: "Название" }],
    isRequired: false,
  },
];
