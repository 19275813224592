import React from "react";
import { Link } from "react-router-dom";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Checkbox,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import { PATHS } from "src/constant";
import { TableHeaderComponent } from "./table-header";
import { rowsPerPageOptions, TablePaginatorComponent } from "./table-paginator";
import { Order, TableComponentProps } from "./table-types";
import { getComparator, stableSort } from "./table-utils";
import { tableStyles } from "./table-styles";

export const TableComponent = (props: TableComponentProps) => {
  const {
    columns,
    data,
    onEditingClick,
    selectedRows,
    setSelectedRow,
    icons,
    endpoint,
  } = props;
  const classes = tableStyles();

  // for paginator
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageOptions[0]);

  // for sorting
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<any>(null);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.id as string);
      setSelectedRow(newSelecteds);
      return;
    }
    setSelectedRow([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selectedRows.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }
    setSelectedRow(newSelected);
  };

  const isSelected = (id: string) => selectedRows.indexOf(id) !== -1;

  if (!data) {
    return null;
  }

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHeaderComponent
            numSelected={selectedRows.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={data.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCellsData={columns}
          />
          <TableBody>
            {stableSort(data, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: any, index: number) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    onClick={(event) => handleClick(event, row.id)}
                    aria-checked={isItemSelected}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </TableCell>
                    {columns.map((column) => {
                      const value = row[column.id as string];
                      return (
                        <TableCell
                          key={column.id as string}
                          align={
                            column.align as
                              | "inherit"
                              | "left"
                              | "center"
                              | "right"
                              | "justify"
                              | undefined
                          }
                          padding="none"
                        >
                          <Tooltip
                            title={value ? value : false}
                            placement="top"
                            disableHoverListener={value ? false : true}
                            arrow
                          >
                            <div
                              className={classes.tableCell}
                              style={{ width: column.minWidth as number }}
                            >
                              {value}
                            </div>
                          </Tooltip>
                        </TableCell>
                      );
                    })}
                    <TableCell align="center">
                      {/* {icons === "all" ? (
                        <> */}
                      {/* <VisibilityIcon /> */}

                      {/* <Link
                            onClick={() => {
                              onEditingClick(row.id);
                            }}
                            to={PATHS.TECHOPERATIONS_PAGE + PATHS.EDIT_PAGE}
                            className="link"
                          >
                            <EditIcon className={classes.editIcon} />
                          </Link> */}

                      {/* <DeleteIcon className={classes.deleteIcon} /> */}
                      {/* </>
                      ) : ( */}
                      {(endpoint === "AppUsers" || endpoint === "Seeds") && (
                        <Link
                          onClick={() => {
                            onEditingClick(row.id);
                          }}
                          to={PATHS.CATALOG_PAGE + PATHS.EDIT_PAGE}
                          className="link"
                        >
                          <EditIcon className={classes.editIcon} />
                        </Link>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePaginatorComponent
        rows={data}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </Paper>
  );
};
