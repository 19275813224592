import { combineReducers } from "redux";
import { infoDataReducer, INFO_DATA_KEY } from "./modules/info-data";
import { CATALOG_PAGE_KEY, catalogReducer } from "./pages/catalog-page";
import {
  observationsReducer,
  OBSERVATIONS_PAGE_KEY,
} from "./pages/observation-page";
import {
  techOperationsReducer,
  TECHOPERATIONS_PAGE_KEY,
} from "./pages/tech-operations-page";

const dynamicReducers = {
  [INFO_DATA_KEY]: infoDataReducer,
  [CATALOG_PAGE_KEY]: catalogReducer,
  [TECHOPERATIONS_PAGE_KEY]: techOperationsReducer,
  [OBSERVATIONS_PAGE_KEY]: observationsReducer,
};

export default combineReducers(dynamicReducers);
