import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import "./App.css";
import { AppRouter } from "./Router";
import { MuiTheme } from "./styles/mui-theme";

function App() {
  return (
    <ThemeProvider theme={MuiTheme}>
      <AppRouter />
    </ThemeProvider>
  );
}

export default App;
