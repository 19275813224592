import { createSelector } from "reselect";
import { getInfoCropTypes } from "src/modules/info-data";
import { getCatalogData } from "src/pages/catalog-page/catalog-page-selectors";

export const getUniqCropType = createSelector(getInfoCropTypes, (data: any) =>
  data
    .map((item: { name: string }) => item.name)
    .filter(
      (value: any, index: any, self: string | any[]) =>
        self.indexOf(value) === index
    )
    .sort()
);

export const getUniqVariety = createSelector(getCatalogData, (data: any) =>
  data
    .map((item: { varietyName: string }) => item.varietyName)
    .filter(
      (value: any, index: any, self: string | any[]) =>
        self.indexOf(value) === index
    )
    .sort()
);
