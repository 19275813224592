import { createSelector } from "reselect";
import { ModifiedParamsTypes } from "src/common-types";
import { getInfoPositions, getInfoFarms } from "src/modules/info-data";
import { CatalogDataType } from "src/pages/catalog-page";
import {
  getEditObjData,
  getModifiedParams,
} from "../../catalog-editing-page-selectors";

export const getUniqPosition = createSelector(getInfoPositions, (data: any) =>
  data
    .map((item: { name: string }) => item.name)
    .filter(
      (value: any, index: any, self: string | any[]) =>
        self.indexOf(value) === index
    )
    .sort()
);

export const getUniqFarm = createSelector(getInfoFarms, (farms: any) =>
  farms
    ? farms
        .filter((farm: any) => farm.holdingId)
        .map((farm: { name: string }) => farm.name)
        .filter(
          (name: any, index: any, self: string | string[]) =>
            self.indexOf(name) === index
        )
        .sort()
    : null
);

export const getImportFarmNames = createSelector(
  getEditObjData,
  getModifiedParams,
  (
    editObjData: CatalogDataType | undefined,
    modifiedParams: {} | ModifiedParamsTypes
  ) =>
    modifiedParams && modifiedParams["importFarmName"]
      ? modifiedParams["importFarmName"]
      : editObjData && editObjData["importFarmName"]
      ? (editObjData["importFarmName"] as string).split(",")
      : []
);
