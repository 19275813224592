import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  addToModifiedParams,
  setModifiedParamsValidity,
} from "../../catalog-editing-page-actions";
import {
  getEditObjData,
  getModifiedParams,
} from "../../catalog-editing-page-selectors";
import { AppUsersEditForm } from "./appusers-edit-form";
import {
  getUniqFarm,
  getUniqPosition,
  getImportFarmNames,
} from "./appusers-edit-form-selectors";

const mapStateToProps = createStructuredSelector({
  editObjData: getEditObjData,
  uniqFarm: getUniqFarm,
  uniqPosition: getUniqPosition,
  modifiedParams: getModifiedParams,
  importFarmNames: getImportFarmNames,
});

const mapDispatchToProps = {
  addToModifiedParams,
  setModifiedParamsValidity,
};

export const AppUsersEditFormController = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppUsersEditForm);
