import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  addToModifiedParams,
  setModifiedParamsValidity,
} from "../../catalog-editing-page-actions";
import {
  getEditObjData,
  getModifiedParams,
} from "../../catalog-editing-page-selectors";
import { SzrEditForm } from "./szr-edit-form";
import { getUniqType, getUniqUnit } from "./szr-edit-form-selectors";

const mapStateToProps = createStructuredSelector({
  editObjData: getEditObjData,
  uniqType: getUniqType,
  uniqUnit: getUniqUnit,
  modifiedParams: getModifiedParams,
});

const mapDispatchToProps = {
  addToModifiedParams,
  setModifiedParamsValidity,
};

export const SzrEditFormController = connect(
  mapStateToProps,
  mapDispatchToProps
)(SzrEditForm);
