export const TECHOPERATIONS_PAGE_KEY = "techOperations_page";

export const TECHOPERATIONS_INITIAL_STATE = {
  data: undefined,
  selectedRowsId: [],
  selectedPeriod: {
    dateFrom: new Date(new Date().setDate(new Date().getDate() - 9)).getTime(),
    dateTo: new Date().getTime(),
  },
  curSeasonId: "2704B760-D636-11EA-B1CB-3F0E5DDB433F",
};
