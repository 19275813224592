import { createSelector } from "reselect";
import { CatalogPageState } from "./catalog-page-types";
import { CATALOG_PAGE_KEY } from "./catalog-page-constants";

const getState = (appState: any) => appState[CATALOG_PAGE_KEY];

export const getCatalogData = createSelector(
  getState,
  (state: CatalogPageState) => state.data
);

export const getEndpoints = createSelector(
  getState,
  (state: CatalogPageState) => state.endpoints
);

export const getFilterData = createSelector(
  getState,
  (state: CatalogPageState) => state.filterData
);

export const getSelectedRows = createSelector(
  getState,
  (state: CatalogPageState) => state.selectedRowsId
);
