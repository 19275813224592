export const OBSERVATIONS_PAGE_KEY = "observation_page";

export const OBSERVATIONS_INITIAL_STATE = {
  data: undefined,
  selectedRowsId: [],
  selectedPeriod: {
    dateFrom: new Date(new Date().setMonth(new Date().getDate() - 2)).getTime(),
    dateTo: new Date().getTime(),
  },
  curSeasonId: "2704B760-D636-11EA-B1CB-3F0E5DDB433F",
};
