import { createSelector } from "reselect";
import { getCatalogData } from "src/pages/catalog-page/catalog-page-selectors";
import {
  getInfoTechOperationGroupName,
  getInfoTechOperationSubGroupName,
  getInfoTechTypes,
  getInfoFarms,
  getTechAssetCategories,
} from "src/modules/info-data";

export const getUniqTypeName = createSelector(getInfoTechTypes, (data: any) =>
  data
    ? data
        .map((item: { name: string }) => item.name)
        .filter(
          (value: any, index: any, self: string | any[]) =>
            self.indexOf(value) === index
        )
        .sort()
    : null
);

export const getUniqTechBrand = createSelector(getCatalogData, (data: any) =>
  data
    ? data
        .map((item: { importTechBrand: string }) => item.importTechBrand)
        .filter(
          (value: any, index: any, self: string | any[]) =>
            self.indexOf(value) === index
        )
        .sort()
    : null
);

export const getUniqTechModel = createSelector(getCatalogData, (data: any) =>
  data
    ? data
        .map((item: { techModel: string }) => item.techModel)
        .filter(
          (value: any, index: any, self: string | any[]) =>
            self.indexOf(value) === index
        )
        .sort()
    : null
);

export const getUniqFarm = createSelector(getInfoFarms, (data: any) =>
  data
    ? data
        .filter(
          (value: any, index: any, self: string | any[]) =>
            value.holdingId != null
        )
        .map((item: { name: string }) => item.name)
        .filter(
          (value: any, index: any, self: string | any[]) =>
            self.indexOf(value) === index
        )
        .sort()
    : null
);

export const getUniqOwner = createSelector(getCatalogData, (data: any) =>
  data
    ? data
        .map((item: { techOwner: string }) => item.techOwner)
        .filter(
          (value: any, index: any, self: string | any[]) =>
            self.indexOf(value) === index
        )
        .sort()
    : null
);

export const getUniqtechOperationGroupName = createSelector(
  getInfoTechOperationGroupName,
  (data: any) =>
    data
      ? data
          .map((item: { name: string }) => item.name)
          .filter(
            (value: any, index: any, self: string | any[]) =>
              self.indexOf(value) === index
          )
          .sort()
      : null
);

export const getUniqtechOperationSubGroupName = createSelector(
  getInfoTechOperationSubGroupName,
  (data: any) =>
    data
      ? data
          .map((item: { name: string }) => item.name)
          .filter(
            (value: any, index: any, self: string | any[]) =>
              self.indexOf(value) === index
          )
          .sort()
      : null
);

export const getCategory = createSelector(
  getTechAssetCategories,
  (data: any) => data
);
