import React from "react";
import { useStore, useDispatch } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { PATHS } from "src/constant";
import {
  fetchCatalogInfoData,
  fetchInfoDataFarmLands,
} from "src/modules/info-data";
import { useAuth } from "./authContext";

export const AuthenticatedRoute = ({ children, path, ...rest }: any) => {
  const { isAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const store = useStore().getState();

  dispatch(fetchCatalogInfoData());
  dispatch(fetchInfoDataFarmLands());

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return isAuthenticated ? (
          (!store.catalog_page.catalog_editing.modify &&
            // !store.techOperations_page.data
            location.pathname.includes(PATHS.EDIT_PAGE)) ||
          path === "/" ? (
            <Redirect
              to={{
                pathname: PATHS.CATALOG_PAGE,
                state: { from: location },
              }}
            />
          ) : (
            children
          )
        ) : (
          <Redirect
            to={{
              pathname: PATHS.LOGIN_PAGE,
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};
