import React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import {
  Drawer,
  AppBar,
  Toolbar,
  List,
  CssBaseline,
  Typography,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import AppsIcon from "@material-ui/icons/Apps";
import TimelineIcon from "@material-ui/icons/Timeline";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import SettingsIcon from "@material-ui/icons/Settings";
import NaturePeopleIcon from "@material-ui/icons/NaturePeople";
import { PATHS } from "src/constant";
import { sidebarStyles } from "./sidebar-style";

export const SidebarPanel = (props: any) => {
  const { title, sidebarIndex } = props;
  const [open, setOpen] = React.useState(false);

  const classes = sidebarStyles();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // const lisIcons = [
  //   <AppsIcon color="secondary" />,
  //   <TimelineIcon color="secondary" />,
  //   <SettingsIcon color="secondary" />,
  //   <ImportContactsIcon color="secondary" />,
  // ];

  const lisIcons = [<SettingsIcon color="secondary" />];

  const listLinks = [
    // PATHS.TECHOPERATIONS_PAGE,
    PATHS.CATALOG_PAGE,
    // PATHS.OBSERVATIONS_PAGE,
  ];
  // const listLinks = [PATHS.CATALOG_PAGE];

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        color="secondary"
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <Typography variant="h4" noWrap>
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen}>
            {open ? (
              <CloseIcon color="secondary" />
            ) : (
              <MenuIcon color="secondary" />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {/* {["Дашборд", "Отчёты", "Техоперации", "Справочники"].map( */}
          {["Справочники"].map((text, index) => (
            <Link to={listLinks[index]} className="link" key={index}>
              <ListItem
                button
                key={text}
                style={
                  index === sidebarIndex ? { backgroundColor: "orange" } : {}
                }
                // onClick={() => {
                //   setSection(dtdtd[index]);
                //   setMainParams("view");
                // }}
              >
                <ListItemIcon>{lisIcons[index]}</ListItemIcon>
                <ListItemText
                  primary={text}
                  color="secondary"
                  className={classes.listItemText}
                />
              </ListItem>
            </Link>
          ))}
        </List>
      </Drawer>
    </div>
  );
};
