import React from "react";
import { CardMedia, ListItemIcon, Typography } from "@material-ui/core";
import { DateTimeFormatter } from "src/utils/formatters";
import { useObservationCardStyles } from "./observation-card-styles";

export const ObservationCardComponent = (props: any) => {
  const { filterData } = props;

  const classes = useObservationCardStyles();

  return (
    <>
      <div className={classes.description}>
        <div
          className={classes.flexRow}
          style={{
            borderBottom: "1px solid grey",
            marginBottom: 10,
            width: "100%",
            paddingBottom: 5,
          }}
        >
          <div className={classes.flexColumn}>{`№ ${filterData.id}`}</div>
          <div
            className={classes.flexColumn}
          >{`${filterData.farmLand.name}`}</div>
          <div className={classes.flexColumn}>{`${filterData.crop.name}`}</div>
          <div className={classes.flexColumn}>
            {filterData.appUser ? filterData.appUser.fullName : null}
          </div>
          <div className={classes.flexColumn}>
            {DateTimeFormatter(filterData.inputDate, true)}
          </div>
        </div>

        <div className={classes.flexRow}>
          <div className={classes.flexColumn}>
            <Typography className={classes.textAliasesHeader} component="p">
              ХОЗЯЙСТВО:
            </Typography>
            <Typography className={classes.textClass} component="p">
              {filterData.farm.name}
            </Typography>
          </div>
          <div className={classes.flexColumn}>
            <Typography className={classes.textAliasesHeader} component="p">
              СТАДИЯ РОСТА:
            </Typography>

            <Typography className={classes.textClass} component="p">
              {filterData.phenoPhase ? filterData.phenoPhase.name : null}
            </Typography>
          </div>
          <div className={classes.flexColumn}>
            <Typography className={classes.textAliasesHeader} component="p">
              КОММЕНТАРИЙ:
            </Typography>

            <Typography className={classes.textClass} component="p">
              {filterData.comment}
            </Typography>
          </div>
        </div>

        <div
          className={classes.flexRow}
          style={{
            background: "#ff9090",
            width: "100%",
            marginTop: 20,
            paddingLeft: 15,
          }}
        >
          <div
            className={classes.flexColumn}
          >{`Нерациональное использование техники`}</div>
        </div>
      </div>
    </>
  );
};
