import { TableColumnSeedTypes } from "src/common-types";

export const SEED_COLUMNS: TableColumnSeedTypes[] = [
  {
    id: "name",
    label: "Полное название семян",
    minWidth: 340,
    align: "left",
  },
  {
    id: "cropName",
    label: "Культура",
    minWidth: 185,
    align: "left",
  },
  {
    id: "sortName",
    label: "Сорт",
    minWidth: 200,
    align: "left",
  },
  {
    id: "varietyName",
    label: "Репродукция",
    minWidth: 120,
    align: "left",
  },
];
