import { EditFormComponent } from "src/components/edit-form";
import { PERSONALS_EDIT_LIST_ITEMS } from "src/config/edit-form";
import { IPersonalEditFormProps } from "./personal-edit-form-types";

export const PersonalEditForm = (props: IPersonalEditFormProps) => {
  const {
    editObjData,
    addToModifiedParams,
    setModifiedParamsValidity,
    modifiedParams,
    uniqPosition,
    uniqFarm,
    uniqWorkPlace,
    empty,
    isReadonly,
  } = props;

  const listItemsFunc = {
    positionName: uniqPosition,
    importFarmName: uniqFarm,
    workPlaceName: uniqWorkPlace,
  };

  // if (!editObjData) return null;

  return (
    <EditFormComponent
      editName="Персонал"
      listItemsProp={PERSONALS_EDIT_LIST_ITEMS}
      listItemsFunc={listItemsFunc}
      editObjData={editObjData}
      addToModifiedParams={addToModifiedParams}
      empty={empty}
      setModifiedParamsValidity={setModifiedParamsValidity}
      modifiedParams={modifiedParams}
      isReadonly={isReadonly}
    />
  );
};
