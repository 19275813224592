export type ValidationPattern = {
  pattern: RegExp;
  errorMessage: string;
};

class PatternValidator {
  private value: string;
  private readonly validationPattern: ValidationPattern;

  get ErrorMessage(): string {
    return this.validationPattern.errorMessage;
  }

  constructor(value: string, validationPattern: ValidationPattern) {
    this.value = value;
    this.validationPattern = validationPattern;
  }

  public validate = () => {
    const match = this.value.match(this.validationPattern.pattern);
    const isValid = match && match.length === 1 && match[0] === this.value;
    return isValid;
  };
}

export class InputValidator {
  private patternValidators: PatternValidator[] = [];

  constructor(value: string, patterns: ValidationPattern[]) {
    patterns.forEach((pattern) => {
      const patternValidator = new PatternValidator(value, pattern);
      this.patternValidators.push(patternValidator);
    });
  }

  public getValidationError(): string | undefined {
    let errorMessage: string | undefined;

    this.patternValidators.forEach((patternValidator) => {
      if (!patternValidator.validate()) {
        errorMessage = patternValidator.ErrorMessage;
        return;
      }
    });

    return errorMessage;
  }
}
