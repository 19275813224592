import { TableColumnFertiliserTypes } from "src/common-types";

export const FERTILISER_COLUMNS: TableColumnFertiliserTypes[] = [
  {
    id: "name",
    label: "Название удобрения",
    minWidth: 400,
    align: "left",
  },
  {
    id: "unit",
    label: "Единица измерений",
    minWidth: 170,
    align: "left",
  },
  {
    id: "type",
    label: "Тип удобрений",
    minWidth: 230,
    align: "left",
  },
];
