import React from "react";
import { Link, useHistory } from "react-router-dom";
import { TableComponent } from "src/components/table";
import { Sidebar } from "src/modules/sidebar";
import { DialogWindowComponent } from "src/components/alerts";
import { PATHS } from "src/constant";
import { Button, makeStyles } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CircularProgress from "@material-ui/core/CircularProgress";
import { TableColumnTypes } from "src/common-types";
import { ExportDataToExcel } from "./export-data";
import { DateTimeFormatter } from "src/utils/formatters";

export const techOperationsPageStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: 8,
  },
}));

export const SZR_COLUMNS: TableColumnTypes[] = [
  {
    id: "number",
    label: "Номер поля",
    minWidth: 150,
    align: "left",
  },
  {
    id: "crop",
    label: "Культура",
    minWidth: 150,
    align: "left",
  },
  {
    id: "interval",
    label: "Сроки работ",
    minWidth: 250,
    align: "left",
  },
  {
    id: "type",
    label: "Тип операции",
    minWidth: 120,
    align: "left",
  },
  {
    id: "area",
    label: "Завершенная площадь",
    minWidth: 130,
    align: "right",
  },
];

export const TechOperationsComponent = (props: any) => {
  const {
    columns,
    data,
    setEditObjId,
    selectedRows,
    setSelectedRows,
    setModify,
    fetchTechOperationsData,
    // fetchTechOperationsDataAsset,
    selectedPeriod,
    crops,
    techOperationGroup,
    appUserFarms,
    curSeason,
  } = props;

  const history = useHistory();
  // let [openModal, setOpenModal] = React.useState<boolean>(false);
  let [openModal, setOpenModal] = React.useState(false);
  let [loaderData, setLoaderData] = React.useState<boolean>(true);
  // let [dataTable, setDataTable] = React.useState([]);

  const classes = techOperationsPageStyles();

  React.useEffect(() => {
    fetchTechOperationsData();
    setLoaderData(true);
  }, [selectedPeriod, appUserFarms, curSeason, fetchTechOperationsData]);

  React.useEffect(() => {
    setLoaderData(false);
  }, [data]);

  if (!data || !crops || !techOperationGroup) return null;

  let dataTable = data.map((techOper: any, index: number) => {
    let workStartedAt =
      techOper.startedAt === 0 ? "" : DateTimeFormatter(techOper.startedAt);
    let workFinishedAt =
      techOper.finishedAt === 0 ? "" : DateTimeFormatter(techOper.finishedAt);
    let crop = crops.find((x: any) => x.id === techOper.cropId)
      ? crops.find((x) => x.id === techOper.cropId).name
      : "";

    return {
      id: techOper.id,
      crop: crop,
      number: techOper.farmLandName,
      interval: `${workStartedAt} - ${workFinishedAt}`,
      type: techOperationGroup.find(
        (x) => x.id === techOper.techOperationGroupId
      ).name,
      area: techOper.fieldSize,
    };
  });

  // if (!filterData) return null;

  return (
    <div className={classes.root}>
      <Sidebar title="Техоперации" sidebarIndex={0} />

      <main className={classes.content}>
        <DialogWindowComponent
          title="Удаление"
          text={["Вы уверены, что хотите удалить объект/ы?"]}
          open={openModal}
          onCloseWindow={() => setOpenModal(false)}
          isCloseButton={true}
          closeButtonFunc={() => setOpenModal(false)}
          btnActions={[
            {
              textBtn: "Нет",
              colorBtn: "primary",
              variantBtn: "contained",
              hrefBtn: PATHS.TECHOPERATIONS_PAGE,
              funcBtn: () => {
                setOpenModal(false);
              },
            },
            {
              textBtn: "Удалить",
              colorBtn: "error",
              variantBtn: "outlined",
              hrefBtn: PATHS.TECHOPERATIONS_PAGE,
              funcBtn: () => {
                setOpenModal(false);
                // deleteObjData(selectedRows);
                history.go(0);
              },
            },
          ]}
        />
        <div className={classes.toolbar} />

        <ExportDataToExcel />
        {/* <Link
          to={PATHS.TECHOPERATIONS_PAGE + PATHS.EDIT_PAGE}
          className="link"
          style={{
            whiteSpace: "nowrap",
            width: "auto",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{ float: "right", margin: "10px" }}
            startIcon={
              <AddCircleOutlineIcon
                color="secondary"
                style={{
                  height: "30px",
                  width: "30px",
                }}
              />
            }
            // onClick={() => setModify("add")}
          >
            Добавить техоперацию
          </Button>
        </Link> */}
        <TableComponent
          columns={SZR_COLUMNS}
          data={dataTable}
          onEditingClick={(click) => {
            // setModify("edit");
            // setEditObjId(click);
          }}
          selectedRows={selectedRows}
          setSelectedRow={setSelectedRows}
          icons="all"
        />
      </main>
      <div
        style={{
          height: "100%",
          width: "100%",
          background: "rgb(128 128 128 / 24%)",
          position: "absolute",
          top: 0,
          zIndex: 2000,
          paddingTop: "30%",
          display: loaderData ? "block" : "none",
        }}
      >
        <CircularProgress
          style={{
            marginLeft: "50%",
            marginRight: "50%",
          }}
        />
      </div>
    </div>
  );
};
