import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  getCurrentSeason,
  getObservationsData,
  getSelectedPeriod,
  getSelectedRows,
} from "./observation-page-selectors";
import {
  fetchObservationsData,
  setCurrentSeason,
  setSelectedPeriod,
} from "./observation-page-actions";
import { ObservationsComponent } from "./observation-page";
import { getInfoSeasons } from "src/modules/info-data";

const mapStateToProps = createStructuredSelector({
  data: getObservationsData,
  selectedRows: getSelectedRows,
  selectedPeriod: getSelectedPeriod,
  seasons: getInfoSeasons,
  curSeason: getCurrentSeason,
});

const mapDispatchToProps = {
  fetchObservationsData,
  setSelectedPeriod,
  setCurrentSeason,
};

export const ObservationsPageController = connect(
  mapStateToProps,
  mapDispatchToProps
)(ObservationsComponent);

// export const TechOperationsEditingPageController = connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(TechOperationsEditingComponent);
