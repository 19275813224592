import { TableColumnTechnicsTypes } from "src/common-types";

export const TECHNICS_COLUMNS: TableColumnTechnicsTypes[] = [
  {
    id: "techTypeName",
    label: "Тип техники",
    minWidth: 150,
    align: "left",
  },
  {
    id: "importTechBrand",
    label: "Марка",
    minWidth: 130,
    align: "left",
  },
  {
    id: "techModel",
    label: "Модель",
    minWidth: 95,
    align: "left",
  },
  {
    id: "stateNumber",
    label: "Госномер",
    minWidth: 115,
    align: "left",
  },
  {
    id: "inventoryNumber",
    label: "№ Инвентарный",
    minWidth: 140,
    align: "left",
  },
  {
    id: "techFactoryNum",
    label: "VIN",
    minWidth: 100,
    align: "left",
  },
  {
    id: "techEngineNum",
    label: "№ двигателя",
    minWidth: 120,
    align: "left",
  },
  {
    id: "importFarmName",
    label: "Хозяйство",
    minWidth: 110,
    align: "left",
  },
  {
    id: "techOwner",
    label: "Собственник",
    minWidth: 110,
    align: "left",
  },
];
