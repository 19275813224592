import { ValidationPattern } from "src/components/edit-form/edit-form-validator";

const START_FROM_CAPITAL_PATTERN: RegExp = /[A-ZА-ЯЁ][\S ]*/;
const START_FROM_CAPITAL_ERROR_MESSAGE: string =
  "Введенное значение должно начинаться с заглавной буквы";

const ALLOWED_FOR_NAMING_SYMBOLS_PATTERN: RegExp = /[A-ZА-Яa-zа-я-ёЁ ]*/;
const ALLOWED_FOR_NAMING_SYMBOLS_ERROR_MESSAGE: string =
  "Введенное значение содержит запрещенные символы";

const EMAIL_PATTERN: RegExp = /\S+@\S+\.\S\S+/;
const EMAIL_ERROR_MESSAGE: string =
  "Введенное значение не соответствует формату адреса e-mail";

const ONLY_NUMBERS_PATTERN: RegExp = /[0-9]*/;
const ONLY_NUMBERS_PATTERN_ERROR_MESSAGE: string =
  "Введенное значение должно состоять только из цифр";

const PHONE_NUMBER_PATTERN: RegExp = /[0-9-+()]*/;
const PHONE_NUMBER_ERROR_MESSAGE =
  "Введенное значение содержит запрещенные символы";

export const NAMING_VALIDATION_PATTERN: ValidationPattern[] = [
  {
    pattern: START_FROM_CAPITAL_PATTERN,
    errorMessage: START_FROM_CAPITAL_ERROR_MESSAGE,
  },
  {
    pattern: ALLOWED_FOR_NAMING_SYMBOLS_PATTERN,
    errorMessage: ALLOWED_FOR_NAMING_SYMBOLS_ERROR_MESSAGE,
  },
];

export const EMAIL_VALIDATION_PATTERN: ValidationPattern[] = [
  {
    pattern: EMAIL_PATTERN,
    errorMessage: EMAIL_ERROR_MESSAGE,
  },
];

export const ONLY_NUMBERS_VALIDATION_PATTERN: ValidationPattern[] = [
  {
    pattern: ONLY_NUMBERS_PATTERN,
    errorMessage: ONLY_NUMBERS_PATTERN_ERROR_MESSAGE,
  },
];

export const PHONE_NUMBER_VALIDATION_PATTERN: ValidationPattern[] = [
  {
    pattern: PHONE_NUMBER_PATTERN,
    errorMessage: PHONE_NUMBER_ERROR_MESSAGE,
  },
];
