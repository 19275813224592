import { SET_INFO_DATA, SET_INFO_DATA_FARMLAND } from "./info-data-actions";
import { INFO_DATA_INITIAL_STATE } from "./info-data-constants";

export const infoDataReducer = (
  state = INFO_DATA_INITIAL_STATE,
  action: any
) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INFO_DATA:
      return {
        ...state,
        ...payload,
      };
    case SET_INFO_DATA_FARMLAND:
      return {
        ...state,
        farmLands: payload,
      };
    default:
      return state;
  }
};
