import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Grid,
  FormControl,
  TextField,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  InputLabel,
} from "@material-ui/core";
import MUIRichTextEditor from "mui-rte";
import { IEditFormProps } from "./edit-form-types";
import "./edit-form-style.css";
import { EditFormTypes } from "src/common-types";
import { InputValidator, ValidationPattern } from "./edit-form-validator";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    demo: {
      minHeight: 720,
    },
    title: {
      margin: theme.spacing(4, 0, 2),
    },
    formControl: {
      margin: theme.spacing(1),
      width: "500px",
    },
    editor: {
      borderBottom: "1px solid gray",
    },
    disabledForm: {
      pointerEvents: "none",
      opacity: 0.7,
    },
  })
);

export const EditFormComponent = (props: IEditFormProps) => {
  const {
    listItemsProp,
    listItemsFunc,
    editObjData,
    modifiedParams,
    addToModifiedParams,
    setModifiedParamsValidity,
    empty,
    isReadonly,
  } = props;
  const classes = useStyles();

  // if (!empty && Object.keys(editObjData).length === 0) {
  if (!empty && !editObjData) {
    return null;
  }

  const checkFormValidity = (
    itemProps: EditFormTypes,
    modifiedValue: string | string[]
  ) => {
    if (setModifiedParamsValidity != null) {
      if (!isCorrectlyFilledForm(itemProps.id)) {
        setModifiedParamsValidity(false);
        return;
      }

      modifiedValue =
        typeof modifiedValue === "string"
          ? modifiedValue.trim()
          : modifiedValue;

      const inputIsEmpty = modifiedValue.length === 0;
      if (inputIsEmpty && itemProps.isRequired) {
        setModifiedParamsValidity(false);
        return;
      }

      const inputValueIsValid =
        itemProps.validationPatterns && typeof modifiedValue === "string"
          ? isValidInput(modifiedValue as string, itemProps.validationPatterns)
          : true;

      setModifiedParamsValidity(inputValueIsValid);
    }
  };

  const isCorrectlyFilledForm = (lastModifiedParamId) => {
    if (modifiedParams) {
      const isEditMode = editObjData !== undefined;
      const formIsNotModified = Object.keys(modifiedParams).length === 0;

      const formIsValidByDefault = formIsNotModified && isEditMode;
      if (formIsValidByDefault) return true;

      return isEditMode
        ? getEditingValidity(lastModifiedParamId)
        : getAddingValidity(lastModifiedParamId);
    }

    return false;
  };

  const isValidInput = (
    value: string,
    patterns: ValidationPattern[]
  ): boolean => {
    const errorMessage = getInputValidationError(value, patterns);
    return errorMessage === undefined;
  };

  const getInputValidationError = (
    value: string,
    patterns: ValidationPattern[]
  ): string | undefined => {
    let errorMessage: string | undefined;
    if (value) {
      const isEmptyValue = value.trim().length === 0;
      if (isEmptyValue) {
        return;
      }

      const inputValidator = new InputValidator(value, patterns);
      errorMessage = inputValidator.getValidationError();
    }
    return errorMessage;
  };

  const getEditingValidity = (lastModifiedParamId) => {
    if (modifiedParams) {
      let validity = true;

      listItemsProp
        .filter((itemProp) => itemProp.id !== lastModifiedParamId)
        .forEach((itemProp) => {
          const isTrackedProp =
            itemProp.isRequired || itemProp.validationPatterns;

          if (isTrackedProp) {
            const propIsModified = modifiedParams.hasOwnProperty(itemProp.id);

            if (propIsModified) {
              if (!validateInputModification(itemProp)) {
                validity = false;
                return;
              }
            }
          }
        });

      return validity;
    }

    return false;
  };

  const getAddingValidity = (lastModifiedParamId) => {
    if (modifiedParams) {
      let validity = true;

      listItemsProp
        .filter((itemProp) => itemProp.id !== lastModifiedParamId)
        .forEach((itemProp) => {
          const isTrackedProp =
            itemProp.isRequired || itemProp.validationPatterns;

          if (isTrackedProp) {
            const propIsModified = modifiedParams.hasOwnProperty(itemProp.id);

            if (propIsModified) {
              if (!validateInputModification(itemProp)) {
                validity = false;
                return;
              }
            } else if (itemProp.isRequired) {
              validity = false;
              return;
            }
          }
        });
      return validity;
    }

    return false;
  };

  const validateInputModification = (itemProp: EditFormTypes): boolean => {
    if (modifiedParams) {
      const isEmptyValue =
        modifiedParams[itemProp.id] === "" ||
        modifiedParams[itemProp.id] === undefined ||
        modifiedParams[itemProp.id] === null;

      if (isEmptyValue && itemProp.isRequired) {
        return false;
      }

      if (itemProp.validationPatterns) {
        const inputIsValid = isValidInput(
          modifiedParams[itemProp.id],
          itemProp.validationPatterns
        );

        if (!inputIsValid) {
          return false;
        }
      }

      return true;
    }

    return false;
  };

  const defalue = (value: unknown) => {
    if (value) {
      let strValue = value as string;
      let resut = strValue.startsWith('{"blocks"')
        ? strValue
        : `{"blocks":[{"key":"abuud","text":"${strValue.replaceAll(
            '"',
            "'"
          )}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}} `;
      return resut;
    }
    return "";
  };

  const updateCompositionInputs = (
    lastModifiedItem: EditFormTypes,
    lastModifiedValue: string
  ) => {
    listItemsProp.forEach((editFormType: EditFormTypes) => {
      if (editFormType.compositionTemplate) {
        const composition = getComposition(
          editFormType,
          lastModifiedItem,
          lastModifiedValue
        );
        addToModifiedParams({
          [editFormType.id]: composition,
        });
      }
    });
  };

  const getComposition = (
    editFormType: EditFormTypes,
    lastModifiedItem?: EditFormTypes,
    lastModifiedValue?: string
  ) => {
    let composition = "";
    if (editFormType.compositionTemplate) {
      const templateKeys = Object.keys(editFormType.compositionTemplate);

      templateKeys.forEach((key) => {
        composition +=
          lastModifiedItem && lastModifiedItem.id === key
            ? (editFormType.compositionTemplate
                ? lastModifiedValue && editFormType.compositionTemplate[key]
                : "") + lastModifiedValue
            : modifiedParams && modifiedParams[key]
            ? (editFormType.compositionTemplate && modifiedParams[key]
                ? editFormType.compositionTemplate[key]
                : "") + modifiedParams[key]
            : editObjData && editObjData[key]
            ? (editFormType.compositionTemplate
                ? editObjData[key] && editFormType.compositionTemplate[key]
                : "") + editObjData[key]
            : "";
      });
    }

    return composition;
  };

  const getItemValue = (item: EditFormTypes) => {
    const defaultValue = editObjData ? editObjData[item.id] : "";

    let value =
      modifiedParams && modifiedParams[item.id]
        ? modifiedParams[item.id]
        : defaultValue;

    return value;
  };

  const setRelatedItemValue = (
    item: EditFormTypes,
    value: string | string[]
  ) => {
    if (item.relatedTypeId && modifiedParams) {
      if (typeof value !== "string") {
        modifiedParams[item.relatedTypeId] = value[0];
        addToModifiedParams({
          [item.relatedTypeId]: value[0],
        });
      } else {
        modifiedParams[item.relatedTypeId] = value;
        addToModifiedParams({
          [item.relatedTypeId]: value,
        });
      }
    }
  };

  return (
    <div className={isReadonly ? classes.disabledForm : ""}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <div className={classes.demo}>
            {listItemsProp.map((item) => {
              return item.inputs.map((element, ind) => {
                return element.type === "dropdown" ? (
                  <FormControl
                    key={"dropdown_" + item.id + ind}
                    required={item.isRequired}
                    className={classes.formControl + " " + item.styleClasses}
                  >
                    <InputLabel
                      required={item.isRequired}
                      id={"dropdown_" + item.id + ind}
                    >
                      {item.label}
                    </InputLabel>
                    <Select
                      id={"dropdown_" + item.id + ind}
                      key={"dropdown_" + item.id + ind}
                      inputProps={{ readOnly: item.isReadonly }}
                      value={getItemValue(item)}
                      onChange={(event) => {
                        const value = event.target.value as string;
                        addToModifiedParams({
                          [item.id]: value,
                        });
                        setRelatedItemValue(item, value);
                        checkFormValidity(item, value);
                        updateCompositionInputs(item, value);
                      }}
                    >
                      {listItemsFunc &&
                        listItemsFunc[item.id] &&
                        listItemsFunc[item.id].map((valueTech, indexTech) => {
                          return (
                            <MenuItem key={indexTech} value={valueTech}>
                              {valueTech}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                ) : element.type === "checkbox" ? (
                  <FormControlLabel
                    key={"input_" + item.id + ind}
                    control={
                      <Checkbox
                        // checked={element.check}
                        name={item.id}
                        // onChange={(event) =>
                        //   addToModifiedParams({
                        //     [item.id]: event.target.value as string,
                        //   })
                        // }
                      />
                    }
                    label={element.placeholder}
                  />
                ) : element.type === "multiline" ? (
                  <TextField
                    required={item.isRequired}
                    inputProps={{ readOnly: item.isReadonly }}
                    id={"input_" + item.id + ind}
                    key={"input_" + item.id + ind}
                    label={item.label}
                    multiline
                    rows={4}
                    defaultValue={editObjData ? editObjData[item.id] : ""}
                    variant="outlined"
                    className={classes.formControl + " " + item.styleClasses}
                  />
                ) : element.type === "string" ? (
                  <TextField
                    required={item.isRequired}
                    inputProps={{ readOnly: item.isReadonly }}
                    id={"input_" + item.id + ind}
                    key={"input_" + item.id + ind}
                    label={item.label}
                    error={
                      modifiedParams && item.validationPatterns
                        ? getInputValidationError(
                            modifiedParams[item.id],
                            item.validationPatterns
                          ) !== undefined
                        : false
                    }
                    helperText={
                      modifiedParams && item.validationPatterns
                        ? getInputValidationError(
                            modifiedParams[item.id],
                            item.validationPatterns
                          )
                        : ""
                    }
                    defaultValue={
                      editObjData && editObjData[item.id]
                        ? editObjData[item.id]
                        : item.compositionTemplate
                        ? " "
                        : ""
                    }
                    value={
                      modifiedParams
                        ? modifiedParams[item.id]
                        : editObjData
                        ? editObjData[item.id]
                        : " "
                    }
                    onChange={(event) => {
                      const value = event.target.value as string;
                      addToModifiedParams({
                        [item.id]: value,
                      });
                      setRelatedItemValue(item, value);
                      checkFormValidity(item, value);
                      updateCompositionInputs(item, value);
                    }}
                    className={classes.formControl + " " + item.styleClasses}
                  />
                ) : element.type === "multiselect" ? (
                  <FormControl
                    key={"dropdown_" + item.id + ind}
                    required={item.isRequired}
                    className={classes.formControl + " " + item.styleClasses}
                  >
                    <InputLabel
                      required={item.isRequired}
                      id={"dropdown_" + item.id + ind}
                    >
                      {item.label}
                    </InputLabel>
                    <Select
                      multiple={true}
                      id={"dropdown_" + item.id + ind}
                      key={"dropdown_" + item.id + ind}
                      inputProps={{ readOnly: item.isReadonly }}
                      defaultValue={
                        editObjData && editObjData[item.id]
                          ? (editObjData[item.id] as string).split(",")
                          : []
                      }
                      onChange={(event) => {
                        const value = event.target.value as string;
                        addToModifiedParams({
                          [item.id]: value,
                        });
                        setRelatedItemValue(item, value);
                        checkFormValidity(item, value as string | string[]);
                        updateCompositionInputs(item, value as string);
                      }}
                    >
                      {listItemsFunc &&
                        listItemsFunc[item.id] &&
                        listItemsFunc[item.id]
                          .filter((valueTech) => valueTech)
                          .map((valueTech, indexTech) => {
                            return (
                              <MenuItem key={indexTech} value={valueTech}>
                                {valueTech}
                              </MenuItem>
                            );
                          })}
                    </Select>
                  </FormControl>
                ) : (
                  <MUIRichTextEditor
                    id={"richText_" + item.id + ind}
                    key={"richText_" + item.id + ind}
                    readOnly={item.isReadonly}
                    error={
                      modifiedParams && item.validationPatterns
                        ? getInputValidationError(
                            modifiedParams[item.id],
                            item.validationPatterns
                          ) !== ""
                        : false
                    }
                    defaultValue={
                      editObjData ? defalue(editObjData[item.id]) : ""
                    }
                    label="Напишите описание..."
                    onSave={(event) => {
                      const value = event as string;
                      addToModifiedParams({
                        [item.id]: value,
                      });
                      setRelatedItemValue(item, value);
                      checkFormValidity(item, value);
                      updateCompositionInputs(item, value);
                    }}
                    inlineToolbar={true}
                  />
                );
              });
            })}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
