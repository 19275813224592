export const INFO_DATA_KEY = "info_data";

export const INFO_DATA_INITIAL_STATE: any = {
  // crops: [],
  // cropTypes: [],
  // positions: [],
  // techTypes: [],
  // techOperationGroup: [],
  // techOperationSubGroup: [],
  // workPlace: [],
};
