import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { addToModifiedParams } from "../../catalog-editing-page-actions";
import { getEditObjData } from "../../catalog-editing-page-selectors";
import { DiseaseEditForm } from "./disease-edit-form";
import {
  getUniqBioClass,
  getUniqBioGroup,
  getUniqFamily,
  getUniqGenus,
} from "./disease-edit-form-selectors";

const mapStateToProps = createStructuredSelector({
  editObjData: getEditObjData,
  uniqFamily: getUniqFamily,
  uniqGenus: getUniqGenus,
  uniqBioClass: getUniqBioClass,
  uniqBioGroup: getUniqBioGroup,
});

const mapDispatchToProps = {
  addToModifiedParams,
};

export const DiseaseEditFormController = connect(
  mapStateToProps,
  mapDispatchToProps
)(DiseaseEditForm);
