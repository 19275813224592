import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { setSelectedRows } from "../catalog-page-actions";
import {
  setEditObjId,
  setModify,
} from "../catalog-editing-page/catalog-editing-page-actions";
import { getFilterData, getSelectedRows } from "../catalog-page-selectors";
import { CatalogTableComponent } from "./catalog-table";
import { getColumns } from "./catalog-table-selectors";

const mapStateToProps = createStructuredSelector({
  columns: getColumns,
  filterData: getFilterData,
  selectedRows: getSelectedRows,
});

const mapDispatchToProps = {
  setSelectedRows,
  setEditObjId,
  setModify,
};

export const CatalogTableController = connect(
  mapStateToProps,
  mapDispatchToProps
)(CatalogTableComponent);
