import { createSelector } from "reselect";
import { INFO_DATA_KEY } from "./info-data-constants";
import { InfoDataState } from "./info-data-types";

export const getStateInfoData = (appState: any) => appState[INFO_DATA_KEY];

export const getInfoAppUsers = createSelector(
  getStateInfoData,
  (state: InfoDataState) => state.appUsers
);
export const getInfoChemicals = createSelector(
  getStateInfoData,
  (state: InfoDataState) => state.chemicals
);
export const getInfoCrops = createSelector(
  getStateInfoData,
  (state: InfoDataState) => state.crops
);
export const getInfoDepartments = createSelector(
  getStateInfoData,
  (state: InfoDataState) => state.departments
);
export const getInfoEmployees = createSelector(
  getStateInfoData,
  (state: InfoDataState) => state.employees
);
export const getInfoFarms = createSelector(
  getStateInfoData,
  (state: InfoDataState) => state.farms
);
export const getInfoFertilisers = createSelector(
  getStateInfoData,
  (state: InfoDataState) => state.fertilisers
);
export const getInfoPositions = createSelector(
  getStateInfoData,
  (state: InfoDataState) => state.positions
);

export const getInfoSeasons = createSelector(
  getStateInfoData,
  (state: InfoDataState) => state.seasons
);
export const getInfoSeeds = createSelector(
  getStateInfoData,
  (state: InfoDataState) => state.seeds
);

export const getInfoWorkPlace = createSelector(
  getStateInfoData,
  (state: InfoDataState) => state.workPlace
);

export const getInfoCropTypes = createSelector(
  getStateInfoData,
  (state: InfoDataState) => state.cropTypes
);

export const getInfoTechAssets = createSelector(
  getStateInfoData,
  (state: InfoDataState) => state.techAsset
);

export const getInfoTechTypes = createSelector(
  getStateInfoData,
  (state: InfoDataState) => state.techTypes
);

export const getInfoTechOperationGroupName = createSelector(
  getStateInfoData,
  (state: InfoDataState) => state.techOperationGroup
);
export const getInfoTechOperationSubGroupName = createSelector(
  getStateInfoData,
  (state: InfoDataState) => state.techOperationSubGroup
);

export const getInfoFarmLands = createSelector(
  getStateInfoData,
  (state: InfoDataState) => state.farmLands
);

export const getTechAssetCategories = createSelector(
  getStateInfoData,
  (state: InfoDataState) => state.techAssetCategory
);

export const getAppUserFarmLands = createSelector(
  getStateInfoData,
  (state: InfoDataState) => {
    if (!state.farmLands) return;
    return state.farmLands.map((farm) => farm.id);
  }
);
