import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { SidebarPanel } from "./sidebar";

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = {};

export const SidebarPanelController = connect(
  mapStateToProps,
  mapDispatchToProps
)(SidebarPanel);
