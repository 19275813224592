import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { PATHS } from "src/constant";
import { CATALOG_SECTIONS_INFO, filterByValue } from "..";
import { catalogTabpanelStyles } from "./catalog-tabpanel-style";
import { CatalogTabpanelProps } from "./catalog-tabpanel-types";

export const CatalogTabpanelComponent = (props: CatalogTabpanelProps) => {
  const {
    children,
    endpoints,
    data,
    selectedRows,
    setSelectedRows,
    setFilterData,
    setModify,
    setOpenModal,
  } = props;
  const classes = catalogTabpanelStyles();

  const [selectFunc, setSelectFunc] = React.useState("select");
  const [searchText, setSearchText] = React.useState<string>("");

  let catalog_info = CATALOG_SECTIONS_INFO[endpoints];

  const onChangeSearch = (text: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(text.target.value);
  };

  React.useEffect(() => {
    if (!data) return;
    let queryData = filterByValue(data, searchText);
    setFilterData(queryData);
  }, [data, searchText, setFilterData]);

  // TODO optimized component
  return (
    <div className={classes.root}>
      <div className={classes.manualHeader}>{catalog_info.name}</div>
      <form className={classes.inputSearch} noValidate autoComplete="off">
        <TextField
          id="outlined-basic"
          variant="outlined"
          inputProps={{ "aria-label": "description" }}
          label="Найти"
          onChange={onChangeSearch}
          size="small"
          value={searchText}
          style={{
            minWidth: "420px",
          }}
          InputProps={{
            endAdornment: [
              searchText.length > 0 && (
                <CloseIcon
                  key="closeIcon"
                  onClick={() => setSearchText("")}
                  style={{ cursor: "pointer" }}
                />
              ),
              <SearchIcon key="searchIcon" />,
            ],
          }}
        />
        {(endpoints === "AppUsers" || endpoints === "Seeds") && (
          <Link
            to={PATHS.CATALOG_PAGE + PATHS.EDIT_PAGE}
            className="link"
            style={{
              whiteSpace: "nowrap",
              width: "auto",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={
                <AddCircleOutlineIcon
                  color="secondary"
                  style={{
                    height: "30px",
                    width: "30px",
                  }}
                />
              }
              onClick={() => setModify("add")}
            >
              {catalog_info.btnAddName}
            </Button>
          </Link>
        )}
      </form>

      <div className={classes.manualBody}>{children}</div>
      <div className={classes.manualFooter}>
        Выбрано: {selectedRows.length}
        <FormControl style={{ padding: "0 30px", width: 250 }}>
          <Select
            defaultValue="select"
            onChange={(event) => setSelectFunc(event.target.value as string)}
          >
            <MenuItem key="select" value={"select"}>
              Выберите действие
            </MenuItem>
            {(endpoints === "AppUsers" || endpoints === "Seeds") && (
              <MenuItem
                key="delete"
                value={"delete"}
                style={{ color: "#f44336" }}
              >
                Удалить
              </MenuItem>
            )}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          disabled={
            selectFunc === "delete" && selectedRows.length > 0 ? false : true
          }
          onClick={() => setOpenModal(true)}
        >
          Применить
        </Button>
        <Button
          variant="outlined"
          color="primary"
          style={{ border: "3px solid #709d4b", margin: "0 10px" }}
          onClick={() => setSelectedRows([])}
        >
          Отмена
        </Button>
      </div>
    </div>
  );
};
