import React from "react";
import { EditFormComponent } from "src/components/edit-form";
import { DISEASES_EDIT_LIST_ITEMS } from "src/config/edit-form";
import { ISeedEditFormProps } from "./disease-edit-form-types";

export const DiseaseEditForm = (props: ISeedEditFormProps) => {
  const {
    editObjData,
    addToModifiedParams,
    uniqFamily,
    uniqGenus,
    uniqBioClass,
    uniqBioGroup,
    empty,
  } = props;

  const listItemsFunc = {
    family: uniqFamily,
    genus: uniqGenus,
    cladeClass: uniqBioClass,
    cladeGroup: uniqBioGroup,
  };
  // if (!editObjData) return null;

  return (
    <EditFormComponent
      editName="Болезни"
      listItemsProp={DISEASES_EDIT_LIST_ITEMS}
      listItemsFunc={listItemsFunc}
      editObjData={editObjData}
      addToModifiedParams={addToModifiedParams}
      empty={empty}
    />
  );
};
