import { mergeReducers } from "src/modules/extentions";
import {
  catalogEditReducer,
  CATALOG_EDITING_PAGE_KEY,
} from "./catalog-editing-page";
import {
  SET_ENDPOINTS,
  SET_CATALOG_DATA,
  SET_FILTER_DATA,
  SET_SELECTED_ROWS,
  CLEAR_CATALOG_DATA,
} from "./catalog-page-actions";
import { CATALOG_INITIAL_STATE } from "./catalog-page-constants";

const otherReducers = Object.freeze({
  [CATALOG_EDITING_PAGE_KEY]: catalogEditReducer,
});

export const catalogReducer = (state = CATALOG_INITIAL_STATE, action: any) => {
  const mergedState = mergeReducers(state, action, otherReducers);
  const { type, payload } = action;
  switch (type) {
    case SET_ENDPOINTS:
      return {
        ...mergedState,
        endpoints: payload,
      };
    case SET_CATALOG_DATA:
      return {
        ...mergedState,
        data: payload,
      };
    case SET_FILTER_DATA:
      return {
        ...mergedState,
        filterData: payload,
      };
    case SET_SELECTED_ROWS:
      return {
        ...mergedState,
        selectedRowsId: payload,
      };
    case CLEAR_CATALOG_DATA:
      return {
        ...state,
        selectedRowsId: [],
        data: undefined,
        filterData: undefined,
      };
    default:
      return mergedState;
  }
};
