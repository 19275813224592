import { TableColumnPersonalsTypes } from "src/common-types";

export const APPUSERS_COLUMNS: TableColumnPersonalsTypes[] = [
  {
    id: "lastName",
    label: "Фамилия",
    minWidth: 210,
    align: "left",
  },
  {
    id: "firstName",
    label: "Имя",
    minWidth: 150,
    align: "left",
  },
  {
    id: "middleName",
    label: "Отчество",
    minWidth: 185,
    align: "left",
  },
  {
    id: "positionName",
    label: "Должность",
    minWidth: 130,
    align: "left",
  },
  {
    id: "importFarmName", //TODO
    label: "Хозяйство",
    minWidth: 110,
    align: "left",
  },
];
