import React from "react";
import { EditFormComponent } from "src/components/edit-form";
import { PESTS_EDIT_LIST_ITEMS } from "src/config/edit-form";
import { ISeedEditFormProps } from "./pests-edit-form-types";

export const PestEditForm = (props: ISeedEditFormProps) => {
  const { editObjData, addToModifiedParams, empty } = props;
  // if (!editObjData) return null;

  return (
    <EditFormComponent
      editName="Фенофазы"
      listItemsProp={PESTS_EDIT_LIST_ITEMS}
      editObjData={editObjData}
      addToModifiedParams={addToModifiedParams}
      empty={empty}
    />
  );
};
