import { createSelector } from "reselect";
import { OBSERVATIONS_PAGE_KEY } from "./observation-page-constants";

const getState = (appState: any) => appState[OBSERVATIONS_PAGE_KEY];

export const getObservationsData = createSelector(
  getState,
  (state: any) => state.data
);

export const getSelectedRows = createSelector(
  getState,
  (state: any) => state.selectedRowsId
);

export const getSelectedPeriod = createSelector(
  getState,
  (state: any) => state.selectedPeriod
);

export const getCurrentSeason = createSelector(
  getState,
  (state: any) => state.curSeasonId
);
