import { EditFormTypes } from "src/common-types";

export const FERTILISER_EDIT_LIST_ITEMS: EditFormTypes[] = [
  {
    id: "name",
    label: "Название удобрения: ",
    inputs: [{ type: "string", placeholder: "Название СЗР" }],
    isRequired: true,
  },
  {
    id: "unit",
    label: "Единица измерения: ",
    inputs: [{ type: "dropdown", placeholder: "Название СЗР" }],
    isRequired: true,
  },
  {
    id: "type",
    label: "Тип удобрений: ",
    inputs: [{ type: "dropdown", placeholder: "Название СЗР" }],
    isRequired: true,
  },
];
