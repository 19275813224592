import React from "react";
import { EditFormComponent } from "src/components/edit-form";
import { APPUSERS_EDIT_LIST_ITEMS } from "src/config/edit-form";
import { IAppUsersEditFormProps } from "./appusers-edit-form-types";

export const AppUsersEditForm = (props: IAppUsersEditFormProps) => {
  const {
    editObjData,
    addToModifiedParams,
    setModifiedParamsValidity,
    modifiedParams,
    uniqPosition,
    uniqFarm,
    importFarmNames,
    empty,
  } = props;

  const listItemsFunc = {
    positionName: uniqPosition,
    importFarmName: uniqFarm,
    defaultFarmName: importFarmNames,
  };

  // if (!editObjData) return null;

  return (
    <EditFormComponent
      editName="Пользователи"
      listItemsProp={APPUSERS_EDIT_LIST_ITEMS}
      listItemsFunc={listItemsFunc}
      editObjData={editObjData}
      addToModifiedParams={addToModifiedParams}
      empty={empty}
      setModifiedParamsValidity={setModifiedParamsValidity}
      modifiedParams={modifiedParams}
    />
  );
};
