import React from "react";
import { useHistory } from "react-router-dom";
import { Sidebar } from "src/modules/sidebar";
import { DialogWindowComponent } from "src/components/alerts";
import { VerticalTabs } from "src/components/vertical-tabs";
import { CatalogTabpanel } from "./catalog-tabpanel";
import { CatalogTable } from "./catalog-table";
import { catalogPageStyles } from "./catalog-page-styles";
import { CatalogPageSProps } from "./catalog-page-types";
import {
  CATALOG_SECTIONS_ENUM,
  CATALOG_SECTIONS_INFO,
} from "./catalog-page-constants";
import { CatalogList } from "./catalog-list";
import { PATHS } from "src/constant";

export const CatalogPage = (props: CatalogPageSProps) => {
  const {
    clearCatalogData,
    endpoints,
    fetchCatalogData,
    deleteObjData,
    selectedRows,
    setEndpoints,
  } = props;

  let [openModal, setOpenModal] = React.useState<boolean>(false);

  const classes = catalogPageStyles();

  React.useEffect(() => {
    fetchCatalogData();
  }, [fetchCatalogData, endpoints]);

  return (
    <div className={classes.root}>
      <Sidebar title="Справочники" sidebarIndex={0} />

      <main className={classes.content}>
        <DialogWindowComponent
          title="Удаление"
          text={["Вы уверены, что хотите удалить объект/ы?"]}
          open={openModal}
          onCloseWindow={() => setOpenModal(false)}
          isCloseButton={true}
          closeButtonFunc={() => setOpenModal(false)}
          btnActions={[
            {
              textBtn: "Нет",
              colorBtn: "primary",
              variantBtn: "contained",
              hrefBtn: PATHS.CATALOG_PAGE,
              funcBtn: () => {
                setOpenModal(false);
              },
            },
            {
              textBtn: "Удалить",
              colorBtn: "error",
              variantBtn: "outlined",
              hrefBtn: PATHS.CATALOG_PAGE,
              funcBtn: () => {
                setOpenModal(false);
                deleteObjData(selectedRows).then(() => fetchCatalogData());
              },
            },
          ]}
        />
        <div className={classes.toolbar} />

        <VerticalTabs
          defaultValueTab={endpoints}
          listTabsItems={[
            // {
            //   label:
            //     CATALOG_SECTIONS_INFO[CATALOG_SECTIONS_ENUM.TECHNICKS].name,
            //   component: (
            //     <CatalogTabpanel setOpenModal={setOpenModal}>
            //       <CatalogTable />
            //     </CatalogTabpanel>
            //   ),
            //   stateName: CATALOG_SECTIONS_ENUM.TECHNICKS,
            // },
            {
              label:
                CATALOG_SECTIONS_INFO[CATALOG_SECTIONS_ENUM.PERSONALS].name,
              component: (
                <CatalogTabpanel setOpenModal={setOpenModal}>
                  <CatalogTable endpoint={CATALOG_SECTIONS_ENUM.PERSONALS} />
                </CatalogTabpanel>
              ),
              stateName: CATALOG_SECTIONS_ENUM.PERSONALS,
            },
            {
              label: CATALOG_SECTIONS_INFO[CATALOG_SECTIONS_ENUM.APPUSERS].name,
              component: (
                <CatalogTabpanel setOpenModal={setOpenModal}>
                  <CatalogTable endpoint={CATALOG_SECTIONS_ENUM.APPUSERS} />
                </CatalogTabpanel>
              ),
              stateName: CATALOG_SECTIONS_ENUM.APPUSERS,
            },
            // {
            //   label: CATALOG_SECTIONS_INFO[CATALOG_SECTIONS_ENUM.SZR].name,
            //   component: (
            //     <CatalogTabpanel setOpenModal={setOpenModal}>
            //       <CatalogTable />
            //     </CatalogTabpanel>
            //   ),
            //   stateName: CATALOG_SECTIONS_ENUM.SZR,
            // },
            // {
            //   label:
            //     CATALOG_SECTIONS_INFO[CATALOG_SECTIONS_ENUM.FERTILISER].name,
            //   component: (
            //     <CatalogTabpanel setOpenModal={setOpenModal}>
            //       <CatalogTable />
            //     </CatalogTabpanel>
            //   ),
            //   stateName: CATALOG_SECTIONS_ENUM.FERTILISER,
            // },
            {
              label: CATALOG_SECTIONS_INFO[CATALOG_SECTIONS_ENUM.SEEDS].name,
              component: (
                <CatalogTabpanel setOpenModal={setOpenModal}>
                  <CatalogTable endpoint={CATALOG_SECTIONS_ENUM.SEEDS} />
                </CatalogTabpanel>
              ),
              stateName: CATALOG_SECTIONS_ENUM.SEEDS,
            },
            // {
            //   label:
            //     CATALOG_SECTIONS_INFO[CATALOG_SECTIONS_ENUM.PHENOPHASE].name,
            //   component: (
            //     <CatalogTabpanel setOpenModal={setOpenModal}>
            //       <CatalogList type={CATALOG_SECTIONS_ENUM.PHENOPHASE} />
            //     </CatalogTabpanel>
            //   ),
            //   stateName: CATALOG_SECTIONS_ENUM.PHENOPHASE,
            // },
            // {
            //   label: CATALOG_SECTIONS_INFO[CATALOG_SECTIONS_ENUM.WEEDS].name,
            //   component: (
            //     <CatalogTabpanel setOpenModal={setOpenModal}>
            //       <CatalogList type={CATALOG_SECTIONS_ENUM.WEEDS} />
            //     </CatalogTabpanel>
            //   ),
            //   stateName: CATALOG_SECTIONS_ENUM.WEEDS,
            // },
            // {
            //   label: CATALOG_SECTIONS_INFO[CATALOG_SECTIONS_ENUM.PESTS].name,
            //   component: (
            //     <CatalogTabpanel setOpenModal={setOpenModal}>
            //       <CatalogList type={CATALOG_SECTIONS_ENUM.PESTS} />
            //     </CatalogTabpanel>
            //   ),
            //   stateName: CATALOG_SECTIONS_ENUM.PESTS,
            // },
            // {
            //   label: CATALOG_SECTIONS_INFO[CATALOG_SECTIONS_ENUM.DISEASES].name,
            //   component: (
            //     <CatalogTabpanel setOpenModal={setOpenModal}>
            //       <CatalogList type={CATALOG_SECTIONS_ENUM.DISEASES} />
            //     </CatalogTabpanel>
            //   ),
            //   stateName: CATALOG_SECTIONS_ENUM.DISEASES,
            // },
          ]}
          onChangeTab={(click) => {
            clearCatalogData();
            setEndpoints(click);
            return new Promise((resolve) => resolve());
          }}
        />
      </main>
    </div>
  );
};
