import { createSelector } from "reselect";
import { TECHOPERATIONS_PAGE_KEY } from "./tech-operations-page-constants";

const getState = (appState: any) => appState[TECHOPERATIONS_PAGE_KEY];

export const getTechOperationsData = createSelector(
  getState,
  (state: any) => state.data
);

export const getSelectedRows = createSelector(
  getState,
  (state: any) => state.selectedRowsId
);

export const getCurrentSeason = createSelector(
  getState,
  (state: any) => state.curSeasonId
);
