import { TableColumnSzrTypes } from "src/common-types";

export const SZR_COLUMNS: TableColumnSzrTypes[] = [
  {
    id: "name",
    label: "Название СЗР",
    minWidth: 400,
    align: "left",
  },
  {
    id: "type",
    label: "Тип СЗР",
    minWidth: 165,
    align: "left",
  },
  {
    id: "unit",
    label: "Единица измерений",
    minWidth: 170,
    align: "left",
  },
];
