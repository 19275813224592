import React, { useEffect, useRef, useState } from "react";
import { Prompt, useHistory } from "react-router-dom";
import { PATHS } from "src/constant";
import { VerticalTabs } from "src/components/vertical-tabs";
import {
  TechnicEditForm,
  PersonalEditForm,
  AppUsersEditForm,
  SzrEditForm,
  FertiliserEditForm,
  SeedEditForm,
  PhenophaseEditForm,
  WeedEditForm,
  PestEditForm,
  DiseaseEditForm,
} from "src/pages/catalog-page/catalog-editing-page/catalog-editing-forms";
import { Sidebar } from "src/modules/sidebar";
import { catalogEditingPageStyles } from "./catalog-editing-page-styles";
import { CatalogEditingTabpanel } from "./catalog-editing-tabpanel";
import { DialogWindowComponent } from "src/components/alerts";
import { CatalogEditingPageProps } from "./catalog-editing-page-types";
import {
  CATALOG_SECTIONS_ENUM,
  CATALOG_SECTIONS_INFO,
} from "../catalog-page-constants";

export const CatalogEditingPage = (props: CatalogEditingPageProps) => {
  const {
    modify,
    endpoints,
    fetchEditObjData,
    clearEditObjData,
    setSelectedRows,
    setEndpoints,
    isFormModified,
  } = props;

  let history = useHistory();
  const classes = catalogEditingPageStyles();

  let [locationPath, setLocationPath] = useState("");
  let [openModal, setOpenModal] = useState(false);
  let [dialogWindowOkCallback, setDialogWindowOkCallback] =
    useState<() => Promise<void>>();

  let [isBlocking, _setIsBlocking] = useState(true);
  const setIsBlockingRef = useRef(isBlocking);
  const setIsBlocking = (value: boolean) => {
    setIsBlockingRef.current = value;
    _setIsBlocking(value);
  };

  useEffect(() => {
    fetchEditObjData();
  }, [fetchEditObjData]);

  useEffect(() => {
    const isNeedToShowDialog = isFormModified && setIsBlockingRef.current;
    if (isNeedToShowDialog) window.addEventListener("beforeunload", onUnload);
    return () => {
      window.removeEventListener("beforeunload", onUnload);
    };
  }, [isFormModified, setIsBlockingRef]);

  const onUnload = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  return (
    <div className={classes.root}>
      <Sidebar title="Справочники" sidebarIndex={1} />

      <main className={classes.content}>
        <Prompt
          message={(location) => {
            const isNeedToShowDialogWindow =
              isFormModified && setIsBlockingRef.current;
            if (isNeedToShowDialogWindow) {
              setLocationPath(location.pathname);
              setOpenModal(true);
              return false;
            }
            return true;
          }}
        />
        <DialogWindowComponent
          title="Сохранение"
          text={[
            "Вы действительно хотите покинуть страницу?",
            "Изменения НЕ СОХРАНЯТСЯ!",
          ]}
          open={openModal}
          onCloseWindow={() => setOpenModal(false)}
          isCloseButton={true}
          closeButtonFunc={() => setOpenModal(false)}
          btnActions={[
            {
              textBtn: "OK",
              colorBtn: "primary",
              variantBtn: "outlined",
              hrefBtn: locationPath ? locationPath : PATHS.CATALOG_PAGE,
              funcBtn: () => {
                clearEditObjData();
                setOpenModal(false);
                setIsBlocking(false);
                dialogWindowOkCallback?.call(null);
              },
            },
            {
              textBtn: "Отмена",
              colorBtn: "primary",
              variantBtn: "outlined",
              funcBtn: () => {
                setIsBlocking(true);
                setOpenModal(false);
                setLocationPath("");
                setDialogWindowOkCallback(undefined);
              },
            },
          ]}
        />

        <div className={classes.toolbar} />

        <VerticalTabs
          defaultValueTab={endpoints}
          listTabsItems={[
            // {
            //   label:
            //     CATALOG_SECTIONS_INFO[CATALOG_SECTIONS_ENUM.TECHNICKS].name,
            //   component: (
            //     <CatalogEditingTabpanel
            //       setOpenModal={setOpenModal}
            //       setIsBlocking={setIsBlocking}
            //     >
            //       <TechnicEditForm empty={modify === "add" ? true : false} />
            //     </CatalogEditingTabpanel>
            //   ),
            //   stateName: CATALOG_SECTIONS_ENUM.TECHNICKS,
            // },
            {
              label:
                CATALOG_SECTIONS_INFO[CATALOG_SECTIONS_ENUM.PERSONALS].name,
              component: (
                <CatalogEditingTabpanel
                  setOpenModal={setOpenModal}
                  setIsBlocking={setIsBlocking}
                >
                  <PersonalEditForm empty={modify === "add" ? true : false} />
                </CatalogEditingTabpanel>
              ),
              stateName: CATALOG_SECTIONS_ENUM.PERSONALS,
            },
            {
              label: CATALOG_SECTIONS_INFO[CATALOG_SECTIONS_ENUM.APPUSERS].name,
              component: (
                <CatalogEditingTabpanel
                  setOpenModal={setOpenModal}
                  setIsBlocking={setIsBlocking}
                >
                  <AppUsersEditForm empty={modify === "add" ? true : false} />
                </CatalogEditingTabpanel>
              ),
              stateName: CATALOG_SECTIONS_ENUM.APPUSERS,
            },
            // {
            //   label: CATALOG_SECTIONS_INFO[CATALOG_SECTIONS_ENUM.SZR].name,
            //   component: (
            //     <CatalogEditingTabpanel
            //       setOpenModal={setOpenModal}
            //       setIsBlocking={setIsBlocking}
            //     >
            //       <SzrEditForm empty={modify === "add" ? true : false} />
            //     </CatalogEditingTabpanel>
            //   ),
            //   stateName: CATALOG_SECTIONS_ENUM.SZR,
            // },
            // {
            //   label:
            //     CATALOG_SECTIONS_INFO[CATALOG_SECTIONS_ENUM.FERTILISER].name,
            //   component: (
            //     <CatalogEditingTabpanel
            //       setOpenModal={setOpenModal}
            //       setIsBlocking={setIsBlocking}
            //     >
            //       <FertiliserEditForm empty={modify === "add" ? true : false} />
            //     </CatalogEditingTabpanel>
            //   ),
            //   stateName: CATALOG_SECTIONS_ENUM.FERTILISER,
            // },
            {
              label: CATALOG_SECTIONS_INFO[CATALOG_SECTIONS_ENUM.SEEDS].name,
              component: (
                <CatalogEditingTabpanel
                  setOpenModal={setOpenModal}
                  setIsBlocking={setIsBlocking}
                >
                  <SeedEditForm empty={modify === "add" ? true : false} />
                </CatalogEditingTabpanel>
              ),
              stateName: CATALOG_SECTIONS_ENUM.SEEDS,
            },
            // {
            //   label:
            //     CATALOG_SECTIONS_INFO[CATALOG_SECTIONS_ENUM.PHENOPHASE].name,
            //   component: (
            //     <CatalogEditingTabpanel setOpenModal={setOpenModal}>
            //       <PhenophaseEditForm empty={modify === "add" ? true : false} />
            //     </CatalogEditingTabpanel>
            //   ),
            //   stateName: CATALOG_SECTIONS_ENUM.PHENOPHASE,
            // },
            // {
            //   label: CATALOG_SECTIONS_INFO[CATALOG_SECTIONS_ENUM.WEEDS].name,
            //   component: (
            //     <CatalogEditingTabpanel setOpenModal={setOpenModal}>
            //       <WeedEditForm empty={modify === "add" ? true : false} />
            //     </CatalogEditingTabpanel>
            //   ),
            //   stateName: CATALOG_SECTIONS_ENUM.WEEDS,
            // },
            // {
            //   label: CATALOG_SECTIONS_INFO[CATALOG_SECTIONS_ENUM.PESTS].name,
            //   component: (
            //     <CatalogEditingTabpanel setOpenModal={setOpenModal}>
            //       <PestEditForm empty={modify === "add" ? true : false} />
            //     </CatalogEditingTabpanel>
            //   ),
            //   stateName: CATALOG_SECTIONS_ENUM.PESTS,
            // },
            // {
            //   label: CATALOG_SECTIONS_INFO[CATALOG_SECTIONS_ENUM.DISEASES].name,
            //   component: (
            //     <CatalogEditingTabpanel setOpenModal={setOpenModal}>
            //       <DiseaseEditForm empty={modify === "add" ? true : false} />
            //     </CatalogEditingTabpanel>
            //   ),,
            //   stateName: CATALOG_SECTIONS_ENUM.DISEASES,
            // },
          ]}
          onChangeTab={(click) => {
            const isNeedToShowDialogWindow =
              isFormModified && setIsBlockingRef.current;
            if (isNeedToShowDialogWindow) {
              setOpenModal(true);
              setIsBlocking(false);
              setDialogWindowOkCallback(() => () => changeTab(click));
            } else {
              return changeTab(click);
            }

            function changeTab(click: string): Promise<void> {
              history.push(PATHS.CATALOG_PAGE);
              clearEditObjData();
              setSelectedRows([]);
              setEndpoints(click);
              return new Promise((resolve) => resolve());
            }
          }}
        />
      </main>
    </div>
  );
};
