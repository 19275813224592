import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { PATHS } from "src/constant";
import { CATALOG_SECTIONS_INFO } from "../../catalog-page-constants";
import { catalogEditingTabpanelStyles } from "./catalog-editing-tabpanel-styles";
import { CatalogEditingTabpanelProps } from "./catalog-editing-tabpanel-types";

export const CatalogEditingTabpanelComponent = (
  props: CatalogEditingTabpanelProps
) => {
  const {
    children,
    endpoints,
    modify,
    modifiedParams,
    areValidModifiedParams,
    patchEditObjData,
    postObjData,
    clearEditObjData,
    setOpenModal,
    setIsBlocking,
    isFormModified,
  } = props;
  const classes = catalogEditingTabpanelStyles();
  let history = useHistory();
  let catalog_info = CATALOG_SECTIONS_INFO[endpoints];

  const renderSaveButton = () => {
    return areValidModifiedParams ? renderLink() : getSaveButton();
  };

  const renderCancelButton = () => {
    return (
      <Button
        variant="outlined"
        onClick={() => {
          if (isFormModified) {
            setOpenModal(true);
            return;
          }

          clearEditObjData();
          history.push(PATHS.CATALOG_PAGE);
        }}
      >
        Отмена
      </Button>
    );
  };

  const renderLink = () => {
    return (
      <Link to={PATHS.CATALOG_PAGE} className="link">
        {getSaveButton()}
      </Link>
    );
  };

  const getSaveButton = () => {
    return (
      <Button
        disabled={!areValidModifiedParams}
        variant="contained"
        color="primary"
        onClick={() => {
          setIsBlocking(false);
          modify === "add"
            ? postObjData(modifiedParams)
            : patchEditObjData(modifiedParams);
          clearEditObjData();
        }}
        style={{ marginRight: 8 }}
      >
        Сохранить
      </Button>
    );
  };

  return (
    <div className={classes.root}>
      <div
        className={classes.manualHeader}
      >{`${catalog_info.name} / Редактирование`}</div>
      <div className={classes.manualBody}>{children}</div>
      <div className={classes.manualFooter}>
        {renderSaveButton()}
        {renderCancelButton()}
      </div>
    </div>
  );
};
