import {
  SET_CURRENT_SEASON,
  SET_OBSERVATIONS_DATA,
  SET_SELECTED_PERIOD,
  SET_SELECTED_ROWS,
} from "./observation-page-actions";
import { OBSERVATIONS_INITIAL_STATE } from "./observation-page-constants";

export const observationsReducer = (
  state = OBSERVATIONS_INITIAL_STATE,
  action: any
) => {
  const { type, payload } = action;
  switch (type) {
    case SET_OBSERVATIONS_DATA:
      return {
        ...state,
        data: payload,
      };
    case SET_SELECTED_ROWS:
      return {
        ...state,
        selectedRowsId: payload,
      };
    case SET_SELECTED_PERIOD:
      return {
        ...state,
        selectedPeriod: payload,
      };
    case SET_CURRENT_SEASON:
      return {
        ...state,
        curSeasonId: payload,
      };
    default:
      return state;
  }
};
