import { EditFormTypes } from "src/common-types";
import { ONLY_NUMBERS_VALIDATION_PATTERN } from "./validation-constants";

export const TECHNICS_EDIT_LIST_ITEMS: EditFormTypes[] = [
  {
    id: "importTechCategory",
    label: "Категория: ",
    inputs: [{ type: "dropdown", placeholder: "" }],
    isRequired: true,
  },
  {
    id: "techTypeName",
    label: "Тип техники: ",
    inputs: [{ type: "dropdown", placeholder: "Не выбран" }],
    isRequired: true,
  },
  {
    id: "importTechBrand",
    label: "Марка: ",
    inputs: [{ type: "dropdown", placeholder: "Не выбрана" }],
  },
  {
    id: "techModel",
    label: "Модель: ",
    inputs: [{ type: "dropdown", placeholder: "8330" }],
  },
  {
    id: "stateNumber",
    label: "Госномер: ",
    inputs: [{ type: "string", placeholder: "A380HB36" }],
  },
  {
    id: "inventoryNumber",
    label: "№ инвентарный: ",
    inputs: [{ type: "string", placeholder: "" }],
    isRequired: true,
  },
  {
    id: "techFactoryNum",
    label: "VIN:",
    inputs: [{ type: "string", placeholder: "" }],
  },
  {
    id: "techEngineNum",
    label: "№ двигателя:",
    inputs: [{ type: "string", placeholder: "" }],
  },
  {
    id: "importFarmName",
    label: "Производственное хозяйство: ",
    inputs: [{ type: "multiselect", placeholder: "" }],
    isRequired: true,
  },
  {
    id: "techOperationGroupName",
    label: "Группа техопераций: ",
    inputs: [{ type: "dropdown", placeholder: "" }],
    isRequired: true,
  },
  {
    id: "techOperationSubGroupName",
    label: "Подгруппа техопераций: ",
    inputs: [{ type: "multiselect", placeholder: "" }],
    isRequired: true,
  },
  {
    id: "rfid",
    label: "RFID-метка: ",
    inputs: [{ type: "string", placeholder: "" }],
    isRequired: true,
    validationPatterns: ONLY_NUMBERS_VALIDATION_PATTERN,
  },
  {
    id: "name",
    label: "Отображаемое имя",
    inputs: [{ type: "string", placeholder: "" }],
    isReadonly: true,
    styleClasses: "wide-input",
    compositionTemplate: {
      techTypeName: "",
      importTechBrand: " ",
      techModel: " ",
      stateNumber: ", гос.ном. ",
      inventoryNumber: ", инв.ном. ",
      techFactoryNum: ", зав.ном. ",
      techEngineNum: ", ном.двиг. ",
    },
  },
];
