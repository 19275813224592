import React from "react";
import { TableComponent } from "src/components/table";
import { CatalogTableProps } from "./catalog-table-types";

export const CatalogTableComponent = (props: CatalogTableProps) => {
  const {
    columns,
    filterData,
    setEditObjId,
    selectedRows,
    setSelectedRows,
    setModify,
    endpoint,
  } = props;

  if (!filterData) return null;

  return (
    <TableComponent
      columns={columns}
      data={filterData}
      onEditingClick={(click) => {
        setModify("edit");
        setEditObjId(click);
      }}
      selectedRows={selectedRows}
      setSelectedRow={setSelectedRows}
      // icons="all"
      endpoint={endpoint}
    />
  );
};
